import http from "./httpService";
import { AxiosResponse } from "axios";

const endpoint = "/payments";

export async function getTiers(progressFunction: (progress: number) => {}) {
  try {
    const res: AxiosResponse<{ [key: string]: any }> = await http.get(
      endpoint + "/products",
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}
export async function getCustomer(progressFunction: (progress: number) => {}) {
  try {
    const res: AxiosResponse<{ [key: string]: any }> = await http.get(
      endpoint + "/customer",
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function addSubscription(
  paymentMethodID: string,
  priceID: string,
  discountCode: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<string> = await http.post(
      endpoint + "/subscription",
      { paymentMethodID, priceID, discountCode },
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}
export async function cancelSubscription(
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<string> = await http.delete(
      endpoint + "/subscription",
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function continueSubscription(
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<string> = await http.put(
      endpoint + "/subscription",
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function updateDefaultPaymentMethod(
  paymentMethodID: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<string> = await http.post(
      endpoint + "/paymentmethod",
      { paymentMethodID },
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function removeDefaultPaymentMethod(
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<string> = await http.delete(
      endpoint + "/paymentmethod",
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}
