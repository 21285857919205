import React from "react";
import { IData, IInput } from "../../../types/generics";
import SecondaryHeader from "../../common/text/secondaryHeader";
import { titleCase } from "../../../utils/allowables";
import TertiaryHeader from "../../common/text/tertiaryHeader";
import InputTable from "../../common/form/inputTable";
import Units from "../../common/text/units";

type MassTabProps = {
  levels: IData[];
  deadLoads: IData[];
  onUpdateDeadLoad: (property: string, value: string, index: number) => void;
  units: string;
};

const MassTab = ({
  levels,
  deadLoads,
  onUpdateDeadLoad,
  units,
}: MassTabProps) => {
  const directions = ["horizontal", "vertical"];
  const getAssemblies = (direction: string, levelName: string) => {
    return deadLoads
      .map((d, idx) => {
        return { ...d, originalIndex: idx } as IData;
      })
      .filter((d) => d.levelName === levelName && d.direction === direction);
  };

  const raiseUpdateDeadLoad = (
    property: string,
    value: string,
    index: number,
    assemblies: IData[]
  ) => {
    const thisAssembly = assemblies[index];
    onUpdateDeadLoad(property, value, thisAssembly.originalIndex);
  };

  const getAssInputs = (direction: string): IInput[] => {
    return [
      {
        name: "name",
        label: "Name",
        type: "text",
        disabled: true,
        setWidth: 100,
      },
      {
        name: "area",
        label: direction === "horizontal" ? "Area" : "Length",
        type: "number",
        setWidth: 75,
      },
      {
        name: "totalWeight",
        label: <Units text={"Ass. Mass"} units={units} type="pressure" />,
        type: "number",
        disabled: true,
        setWidth: 50,
      },
      {
        name: direction + "Mass",
        label: <Units text={"Total Mass"} units={units} type="mass" />,
        type: "number",
        setWidth: 75,
        disabled: true,
      },
    ];
  };

  const calculateTotalMass = (assemblies: IData[], direction: string) => {
    let mass = 0;
    assemblies.forEach((a) => {
      mass += a[direction + "Mass"];
    });
    return mass;
  };

  return (
    <>
      <SecondaryHeader text="Structure Mass Distribution" />
      <div className="row">
        {directions.map((direction) => (
          <div className="col text-center" key={direction}>
            <b>
              <Units
                text={`Total ${titleCase(direction)} Mass: ${calculateTotalMass(
                  deadLoads,
                  direction
                )}`}
                units={units}
                type="mass"
                noParentheses={true}
              />
            </b>
          </div>
        ))}
      </div>
      {levels.map((level, levelIdx) => (
        <div className="text-center list-item" key={levelIdx}>
          <TertiaryHeader text={level.name} />
          <div className="row">
            {directions.map((direction) => {
              const assemblies = getAssemblies(direction, level.name);
              return (
                <div className="col text-center" key={direction}>
                  <b>
                    <Units
                      text={`${titleCase(direction)} Mass: ${calculateTotalMass(
                        assemblies,
                        direction
                      )}`}
                      units={units}
                      type="mass"
                      noParentheses={true}
                    />
                  </b>
                  {assemblies.length > 0 ? (
                    <InputTable
                      data={getAssemblies(direction, level.name)}
                      inputs={getAssInputs(direction)}
                      onChange={(
                        property: string,
                        value: string,
                        index: number
                      ) =>
                        raiseUpdateDeadLoad(property, value, index, assemblies)
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default MassTab;
