import React from "react";
import Modal from "react-modal";

import { modalStyle } from "./../../../utils/styles";

type BasicModalProps = {
  isOpen: boolean;
  onClose: Function;
  children: any;
};

const BasicModal = ({ isOpen, onClose, children }: BasicModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      style={modalStyle}
    >
      <div className="text-center">
        <button className="custom-button btn-light" onClick={() => onClose()}>
          Cancel
        </button>
        <hr />
        {children}
      </div>
    </Modal>
  );
};

export default BasicModal;
