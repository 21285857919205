import React, { useState, useEffect } from "react";

import MiniPopup from "../../common/contentArea/miniPopup";
import { IData } from "../../../types/generics";
import Select from "../../common/form/select";
import { ISettings } from "../../../types/userTypes";
import { units } from "../../../utils/allowables";
import Icons from "../../common/icons/icons";

type ProfileSettingsProps = {
  settings: ISettings | null | undefined;
  onUpdateSettings: (
    event: React.FormEvent<HTMLFormElement>,
    data: IData
  ) => void;
  onDeleteUser: () => void;
};

const ProfileSettings = ({
  settings,
  onUpdateSettings,
  onDeleteUser,
}: ProfileSettingsProps) => {
  const [data, setData] = useState<IData>({
    units: "imperial",
  });
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleChange = (property: string, value: string) => {
    let newData = { ...data };
    newData[property] = value;
    setData(newData);
  };

  useEffect(() => {
    const mapToViewModel = () => {
      if (settings) return setData(settings);
    };
    mapToViewModel();
  }, [settings]);

  return (
    <>
      <div className="text-center">
        <b>Accountwide Settings</b>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={(e) => onUpdateSettings(e, data)}>
            <div className="row">
              <div className="col">
                <Select
                  name="units"
                  label="Default Units"
                  options={units}
                  selectedOption={data.units}
                  onChange={(value: string) => handleChange("units", value)}
                  tooltip={{
                    direction: "right",
                    content: (
                      <span>
                        <b>Deafult Units</b>
                        <br />
                        A new project will default to using this selection.
                        <br />
                        You are able to change this on a project by project
                        basis.
                      </span>
                    ),
                  }}
                />
              </div>
              <div className="col"></div>
            </div>
          </form>
          <div className="text-center">
            <button className="custom-button submit-button btn-dark">
              Save Changes
            </button>
          </div>
        </div>
        <div className="col-2">
          {!deleteVisible && (
            <button
              className="custom-button btn-light"
              onClick={() => setDeleteVisible(!deleteVisible)}
            >
              <Icons iconType="markDelete" /> Delete Account
            </button>
          )}
          {deleteVisible && (
            <button
              className="custom-button btn-dark"
              onClick={() => setDeleteOpen(true)}
            >
              <Icons iconType="check" /> Confirm Account Deletion
            </button>
          )}
        </div>
      </div>
      <MiniPopup
        type="confirm"
        isOpen={deleteOpen}
        onCancel={() => {
          setDeleteOpen(false);
          setDeleteVisible(false);
        }}
        onConfirm={onDeleteUser}
      >
        <b>Are you sure you want to delete your account?</b>
        <br />
        You will lose access to all of your saved projects and any active
        subscription will be cancelled.
      </MiniPopup>
    </>
  );
};

export default ProfileSettings;
