import React from "react";

import { ITableColumns } from "../../../types/generics";
import TableHeader from "./tableHeader";

type TableProps = {
  columns: ITableColumns[];
  data: { [key: string]: any }[];
  selectedIndex?: number | null;
  setSelectedIndex?: Function;
  alternateColors?: boolean;
  tightTable?: boolean;
};

const Table = ({
  columns,
  data,
  selectedIndex,
  setSelectedIndex,
  alternateColors,
  tightTable,
}: TableProps) => {
  const handleClick = (index: number) => {
    if (setSelectedIndex) {
      if (index === selectedIndex) setSelectedIndex(-1);
      else setSelectedIndex(index);
    }
  };

  return (
    <table className="custom-table">
      <TableHeader columns={columns} />
      <tbody>
        {data.map((row, idx) => (
          <tr
            key={idx}
            className={
              "table-row" +
              (selectedIndex === idx ? " selected" : "") +
              (setSelectedIndex ? " clickable" : "") +
              (alternateColors && idx % 2 === 0 ? " table-row-alternate" : "")
            }
            onClick={() => handleClick(idx)}
          >
            {columns.map((column) => (
              <td key={column.path} className="table-cell">
                {column.content ? column.content(row) : row[column.path]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
