import React, { useState, useEffect } from "react";
import "./css/App.css";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import MainNavbar from "./components/navigation/mainNavbar";
import SwitchRouter from "./components/navigation/switchRouter";
import { getCurrentUser, refreshUser } from "./services/userService";
import { IUser } from "./types/userTypes";
import LoadingContext from "./context/loadingContext";
import Loading from "./components/common/loading/loading";
import UserContext from "./context/userContext";
import { stripePublicKey } from "./utils/allowables";
import ErrorBoundary from "./components/navigation/errorBoundary";
import useWindowDimensions from "./hooks/useWindowDimensions";
import WidthTooSmall from "./components/navigation/widthTooSmall";
import MiniBr from "./components/common/contentArea/miniBr";

const stripePromise = loadStripe(stripePublicKey);

function App() {
  const { width } = useWindowDimensions();
  const [user, setUser] = useState<IUser | null>(null);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isReady, setIsReady] = useState(false);
  const tooSmallWidth = 500;

  const loadUser = async () => {
    setLoading(true);
    await refreshUser(async (p) => setProgress(p));
    setUser(getCurrentUser());
    setIsReady(true);
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Elements stripe={stripePromise}>
          <LoadingContext.Provider value={{ setLoading, setProgress }}>
            <UserContext.Provider
              value={{ user, setUser, customer, setCustomer }}
            >
              <div className="main">
                <ToastContainer
                  position="bottom-center"
                  autoClose={2500}
                  closeOnClick
                  pauseOnHover
                  pauseOnFocusLoss
                  theme="dark"
                />
                <Loading loading={loading} progress={progress} />
                <MainNavbar />
                {width < tooSmallWidth && (
                  <WidthTooSmall width={tooSmallWidth} />
                )}
                {isReady && (
                  <main className="container-fluid pad-in">
                    <SwitchRouter />
                  </main>
                )}
                <MiniBr height={50} />
              </div>
            </UserContext.Provider>
          </LoadingContext.Provider>
        </Elements>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
