import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";

import http from "./httpService";
import { tokenKey } from "../utils/allowables";
import { IRegister } from "./authService";
import { IUser, ISettings } from "../types/userTypes";

const endpoint = "/users";
const cookieOptions = {
  expires: 30,
};

http.setJwt(getJwt());

export function setUser(jwt: string) {
  Cookies.set(tokenKey, jwt, cookieOptions);
  http.setJwt(jwt);
}
export function getJwt(): string {
  let jwt = Cookies.get(tokenKey);
  return jwt || "";
}
export function getCurrentUser(): IUser | null {
  try {
    let jwt = getJwt();
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export async function registerUser(
  user: IRegister,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.post(
      endpoint,
      user,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function refreshUser(progressFunction: (progress: number) => {}) {
  try {
    const res: AxiosResponse<{ jwt: string }> = await http.get(
      endpoint,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    setUser(res.data.jwt);
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function updateUser(
  user: IRegister,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ jwt: string }> = await http.put(
      endpoint,
      user,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    setUser(res.data.jwt);
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function updateUserSettings(
  settings: ISettings,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ jwt: string }> = await http.put(
      endpoint + "/settings",
      settings,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    setUser(res.data.jwt);
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function deleteUser(
  navigate: Function,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.delete(
      endpoint,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    http.deleteToken(navigate, "/register");
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function requestPasswordReset(
  email: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.get(
      endpoint + "/passwordreset/" + email,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function updatePassword(
  email: string,
  password: string,
  token: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.post(
      endpoint + "/passwordreset",
      { email, password, token },
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}
