export interface IFaq {
  question: string;
  answer: string;
  category: string;
}

export const faqArray: IFaq[] = [
  {
    question: "What is StructureMate?",
    category: "general",
    answer:
      "StructureMate is a fast, flexible, and easy to use civil engineering calculator.",
  },
];
