import React, { useEffect } from "react";
import { getCurrentUser } from "./../../services/userService";
import { Navigate } from "react-router";
import { toast } from "react-toastify";

type ProtectedRouteProps = {
  component: any;
  route: string;
};

const ProtectedRoute = ({ component, route }: ProtectedRouteProps) => {
  const user = getCurrentUser();
  useEffect(() => {
    if (!user) toast.error(`Please log in to view your ${route} page`);
  }, [route, user]);
  if (!user) {
    return <Navigate to={{ pathname: "/login", hash: route }} />;
  }
  return component;
};

export default ProtectedRoute;
