import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Icons from "../icons/icons";

import Tooltip from "./../tooltip/tooltip";
import { ISelectOption } from "../../../types/generics";

type SelectProps = {
  name: string;
  label: string;
  options: ISelectOption[];
  selectedOption: string;
  onChange: (event: string) => void;
  tooltip?: {
    content: any;
    direction?: string;
  };
  placeholder?: string;
  boldHeader?: boolean;
  hideLabel?: boolean;
  style?: any;
  valueBoxStyle?: any;
};

const Select = ({
  name,
  label,
  options,
  selectedOption,
  onChange,
  tooltip,
  placeholder,
  boldHeader,
  hideLabel,
  style,
  valueBoxStyle,
}: SelectProps) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const finalLabel = boldHeader ? <b>{label}</b> : label;
  const handleSelect = (value: string, disabled: boolean | undefined) => {
    if (!disabled) {
      setSelectOpen(false);
      onChange(value);
    }
  };

  return (
    <div className="input-container">
      {!hideLabel ? (
        <>
          {tooltip ? (
            <Tooltip content={tooltip.content} direction={tooltip.direction}>
              <label htmlFor={name}>{finalLabel}</label>
            </Tooltip>
          ) : (
            <>
              <label htmlFor={name}>{finalLabel}</label>
            </>
          )}
        </>
      ) : null}
      <div
        className={"custom-select" + (selectOpen || isFocused ? " active" : "")}
        onClick={() => {
          setSelectOpen(!selectOpen);
          setIsFocused(true);
        }}
        style={{ ...style, ...valueBoxStyle }}
      >
        <div className="custom-select-box" style={style}>
          {!selectedOption
            ? placeholder || "Make a selection..."
            : options.find((o) => o.id === selectedOption)?.label}
        </div>
        <div
          className={"custom-select-caret " + (selectOpen ? "open" : "closed")}
        >
          {selectOpen ? <Icons iconType="up" /> : <Icons iconType="down" />}
        </div>
        {selectOpen && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setSelectOpen(false);
              setIsFocused(false);
            }}
          >
            <div className="custom-select-dropdown" style={style}>
              {options.map((o, idx) => (
                <div
                  key={idx}
                  className={
                    "custom-select-option" +
                    (o.disabled ? " disabled" : "") +
                    (o.id === selectedOption ? " selected" : "")
                  }
                  onClick={
                    o.disabled ? () => {} : () => handleSelect(o.id, o.disabled)
                  }
                  style={style}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
};

export default Select;
