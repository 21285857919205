import React from "react";

import { faqArray, IFaq } from "../../utils/faqArray";

const Faqs = () => {
  return (
    <div>
      {faqArray.map((faq: IFaq, idx) => (
        <div key={idx}>
          <div style={{ position: "relative" }}>
            <div
              className={
                "color-dot " + (faq.category === "general" ? "bg-info" : "")
              }
            />
            <b className="faq-question">{faq.question}</b>
          </div>
          <p>{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default Faqs;
