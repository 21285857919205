import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { tokenKey } from "../utils/allowables";

if (process.env.NODE_ENV !== "test") {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.response.use(undefined, async (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      toast.error(
        "An unexpected error occured. Please check your internet connection."
      );
    }

    if (error.response && error.response.status === 409) {
      await deleteToken();
    }

    return Promise.reject(error);
  });
}

function setJwt(jwt: string) {
  axios.defaults.headers.common[tokenKey] = jwt;
}

async function deleteToken(
  navigate: Function | undefined = undefined,
  location: string | undefined = undefined
) {
  Cookies.remove(tokenKey);
  delete axios.defaults.headers.common[tokenKey];
  if (navigate) navigate(location || "/login");
}

function handleProgress(progress: ProgressEvent): number {
  return progress.lengthComputable
    ? (progress.loaded / progress.total) * 100
    : 99;
}

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  handleProgress,
  deleteToken,
};

export default http;
