import React, { useState } from "react";
import { IData } from "../../../types/generics";
import SecondaryHeader from "../../common/text/secondaryHeader";
import SeismicCalculatedFields from "./seismicCalculatedFields";
import SeismicInputs from "./seismicInputs";
import TabbedArea from "../../common/contentArea/tabbedArea";

type SeismicTabProps = {
  seismicInfo: IData;
  onUpdateSeismicInfo: (property: string, value: string) => void;
  maxElevation: number;
  lateralSystems: IData[];
};

const SeismicTab = ({
  seismicInfo,
  onUpdateSeismicInfo,
  maxElevation,
  lateralSystems,
}: SeismicTabProps) => {
  const tabs = ["input", "calculations"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const systemSelectOptions = lateralSystems.map((l) => {
    return { id: l.Label, label: `${l.Label} - ${l.Name}` };
  });

  return (
    <>
      <SecondaryHeader text="Seismic Design Criteria" />
      <TabbedArea
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
        tabPlacement="top"
        icons={["input", "calculate"]}
      >
        {selectedTab === "input" ? (
          <SeismicInputs
            seismicInfo={seismicInfo}
            onUpdateSeismicInfo={onUpdateSeismicInfo}
            systemSelectOptions={systemSelectOptions}
          />
        ) : (
          <SeismicCalculatedFields
            seismicInfo={seismicInfo}
            maxElevation={maxElevation}
          />
        )}
      </TabbedArea>
    </>
  );
};

export default SeismicTab;
