import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IInput, IData } from "../../types/generics";
import { login, IRegister } from "../../services/authService";
import { registerUser, getCurrentUser } from "../../services/userService";
import Header from "./../common/text/header";
import LoadingContext from "../../context/loadingContext";
import UserContext from "../../context/userContext";
import InputMap from "../common/form/inputMap";

const Register = () => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const { setUser } = useContext(UserContext);
  let navigate = useNavigate();
  const [data, setData] = useState<IData>({
    name: "",
    email: "",
    password: "",
    role: "individual", // this will be sent as individual, role will be updated based on subscription level
  });

  const handleChange = (property: string, value: string) => {
    let newData = { ...data };
    newData[property] = value;
    setData(newData);
  };

  const inputs: IInput[] = [
    { name: "name", label: "Name", type: "text" },
    { name: "email", label: "Email", type: "text" },
    {
      name: "password",
      label: "Password",
      type: "password",
      usePasswordTooltip: true,
    },
  ];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    const res = await registerUser(data as IRegister, async (p) =>
      setProgress(p / 2)
    );
    if (res.status === 200) {
      const loginRes = await login(
        { email: data.email, password: data.password },
        async (p) => setProgress(p / 2)
      );
      if (loginRes.status === 200) {
        let user = getCurrentUser();
        setUser(user);
        navigate("/profile");
      } else toast.error(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div className="text-center">
      <Header text="Register" />
      <form onSubmit={handleSubmit}>
        <InputMap inputs={inputs} onChange={handleChange} data={data} />
        <br />
        <button className="custom-button btn-dark submit-button">
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;
