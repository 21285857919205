import React from "react";

import Icons from "../icons/icons";
import { Side } from "../../../types/generics";
import { titleCase } from "../../../utils/allowables";

type VerticalTabsProps = {
  tabs: Array<string>;
  icons?: string[];
  selectedTab: string;
  onSelectTab: (tab: string) => void;
  side: Side;
};

const VerticalTabs = ({
  tabs,
  icons,
  selectedTab,
  onSelectTab,
  side,
}: VerticalTabsProps) => {
  return (
    <div style={{ float: side === "right" ? "right" : "left" }}>
      {tabs.map((t, idx) => {
        const isSelected = selectedTab === t;
        return (
          <div
            key={idx}
            className={
              " custom-tab " +
              (isSelected ? "selected" : "") +
              " v-tab v-tab-" +
              side
            }
            onClick={() => onSelectTab(t)}
          >
            &nbsp;
            {icons ? (
              <Icons
                iconType={icons![idx]}
                className={
                  "icon-tabs-vertical" + (isSelected ? "-selected" : "")
                }
              />
            ) : (
              titleCase(t)
            )}
            &nbsp;
          </div>
        );
      })}
    </div>
  );
};

export default VerticalTabs;
