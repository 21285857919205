import React from "react";

import MainTitle from "./../common/loading/mainTitle";
import { sendError, IError } from "../../services/errorService";

interface ErrorBoundaryProps {
  children: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  async componentDidCatch(error: any, info: any) {
    const thisError: IError = {
      errorText:
        typeof error === "string"
          ? error
          : error.message || "error.message undefined",
      componentStack: info.componentStack,
    };
    await sendError(thisError);
  }

  render() {
    return this.state.hasError ? (
      <div className="text-center">
        <br />
        <br />
        <h2>
          <b>Something Went Wrong</b>
        </h2>
        <br />
        <p style={{ margin: 25 }}>
          We've been notified of this error and are rushing to fix it. In the
          meantime refresh the app and try again.
        </p>
        <p>
          If the problem persists please contact{" "}
          <a href="mailto:accounts@structuremate.com">
            accounts@structuremate.com
          </a>
        </p>
        <button
          className="btn btn-block btn-info"
          onClick={() => window.location.replace("/profile")}
        >
          Refresh App
        </button>
        <br />
        <MainTitle />
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
