import React, { useState, useContext } from "react";

import Tiers from "./tiers";
import TabbedArea from "./../common/contentArea/tabbedArea";
import {
  addSubscription,
  cancelSubscription,
  continueSubscription,
  updateDefaultPaymentMethod,
  removeDefaultPaymentMethod,
} from "../../services/subscriptionService";
import CurrentSubscription from "./currentSubscription";
import LoadingContext from "./../../context/loadingContext";
import { toast } from "react-toastify";

type SubscriptionProps = {
  tiers: any[];
  refreshProfile: Function;
};

const Subscription = ({ tiers, refreshProfile }: SubscriptionProps) => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const tabs = ["current", "tiers"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleConfirmSubscription = async (
    paymentMethodID: string,
    priceID: string,
    discountCode: string
  ) => {
    const res = await addSubscription(
      paymentMethodID,
      priceID,
      discountCode,
      async (p) => setProgress(p)
    );
    if (res.status === 200) {
      await refreshProfile();
      toast.success(res.data);
      setSelectedTab("current");
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    setProgress(1);
    const res = await cancelSubscription(async (p) => setProgress(p));
    if (res.status === 200) {
      toast.success(res.data);
      return await refreshProfile();
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleContinueSubscription = async () => {
    setLoading(true);
    setProgress(1);
    const res = await continueSubscription(async (p) => setProgress(p));
    if (res.status === 200) {
      toast.success(res.data);
      return await refreshProfile();
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleAddPaymentMethod = async (paymentMethodID: string) => {
    const res = await updateDefaultPaymentMethod(paymentMethodID, async (p) =>
      setProgress(p)
    );
    if (res.status === 200) {
      toast.success(res.data);
      return await refreshProfile();
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleRemovePaymentMethod = async () => {
    setLoading(true);
    setProgress(1);
    const res = await removeDefaultPaymentMethod(async (p) => setProgress(p));
    if (res.status === 200) {
      toast.success(res.data);
      return await refreshProfile();
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <TabbedArea
      tabPlacement="top"
      tabs={tabs}
      icons={["activeLayer", "layers"]}
      selectedTab={selectedTab}
      onSelectTab={setSelectedTab}
    >
      <div className="text-center">
        {selectedTab === "current" ? (
          <CurrentSubscription
            tiers={tiers}
            onRemovePaymentMethod={handleRemovePaymentMethod}
            onCancelSubscription={handleCancelSubscription}
            onAddPaymentMethod={handleAddPaymentMethod}
            onContinueSubscription={handleContinueSubscription}
          />
        ) : selectedTab === "tiers" ? (
          <Tiers tiers={tiers} onConfirm={handleConfirmSubscription} />
        ) : null}
      </div>
    </TabbedArea>
  );
};

export default Subscription;
