import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import ProjectsHome from "./projectsHome";
import NewProjectTakeoff from "./takeoff/newProjectTakeoff";
import PlaceholderComponent from "../navigation/placeholderComponent";

const ProjectsRedirect = () => {
  const [Component, setComponent] = useState(<PlaceholderComponent />);
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("id");

  useEffect(() => {
    setComponent(
      projectID ? <NewProjectTakeoff projectID={projectID} /> : <ProjectsHome />
    );
  }, [projectID]);

  return Component;
};

export default ProjectsRedirect;
