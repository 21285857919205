import React from "react";
import Modal from "react-modal";

import { popupStyle } from "../../../utils/styles";

type MiniPopupOption = "confirm" | "form";

type MiniPopupProps = {
  type: MiniPopupOption;
  isOpen: boolean;
  onCancel: Function;
  onConfirm?: Function;
  children: any;
  okFocused?: boolean;
  style?: {
    [key: string]: any;
  };
};

const MiniPopup = ({
  type,
  isOpen,
  onCancel,
  onConfirm,
  children,
  okFocused,
  style,
}: MiniPopupProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onCancel()}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      style={{ content: { ...popupStyle.content, ...style } }}
    >
      <div className="text-center">
        {children}
        <br />
        <br />
        <div className="row">
          <div className="col">
            <button
              className="custom-button btn-light"
              onClick={() => onCancel()}
              autoFocus={!okFocused}
            >
              Cancel
            </button>
          </div>
          {type === "confirm" && onConfirm && (
            <div className="col">
              <button
                className="custom-button btn-dark"
                onClick={() => {
                  onCancel();
                  onConfirm();
                }}
                autoFocus={okFocused}
              >
                &nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MiniPopup;
