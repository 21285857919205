import http from "./httpService";

const endpoint = "/email";
export interface IError {
  errorText: string;
  componentStack: string;
}

export interface IContactEmail {
  name: string;
  email: string;
  subject: string;
  text: string;
}

export async function sendContactUsEmail(
  form: IContactEmail,
  progressFunction: (progress: number) => {}
) {
  try {
    return await http.post(
      endpoint + "/contactus",
      form,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
  } catch (ex: any) {
    return ex.response;
  }
}
