import React, { useState } from "react";

import Table from "./../common/table/table";
import { convertStripeCurrency } from "../../utils/allowables";
import ConfirmationModal from "./confirmationModal";
import { getCurrentUser } from "./../../services/userService";
import Icons from "../common/icons/icons";

type TiersProps = {
  tiers: any[];
  onConfirm: Function;
};

const Tiers = ({ tiers, onConfirm }: TiersProps) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);

  const user = getCurrentUser();

  const columns = [
    { path: "name", label: "Tier", content: (t: any) => t.name },
    {
      path: "metadata.projects",
      label: "Number of Projects",
      content: (t: any) => t.metadata.projects,
    },
    {
      path: "metadata.teamBased",
      label: "Work with your Team",
      content: (t: any) =>
        t.metadata.teamBased === "true" ? <Icons iconType="check" /> : null,
    },
    {
      path: "metadata.distributeProjects",
      label: "Distribute Projects",
      content: (t: any) =>
        t.metadata.distributeProjects === "true" ? (
          <Icons iconType="check" />
        ) : null,
    },
    {
      path: "monthly",
      label: "Monthly",
      content: (t: any) =>
        convertStripeCurrency(
          t.prices.find((p: any) => p.recurring.interval === "month")
            .unit_amount
        ),
    },
    {
      path: "yearly",
      label: "Yearly",
      content: (t: any) =>
        convertStripeCurrency(
          t.prices.find((p: any) => p.recurring.interval === "year").unit_amount
        ),
    },
  ];

  return (
    <div className="text-center">
      <Table
        columns={columns}
        data={tiers}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        alternateColors={true}
      />
      {selectedIndex > -1 ? (
        <>
          <b>Tier Description</b>
          <br />
          {tiers[selectedIndex].description}
          <br />
        </>
      ) : null}
      {user && (
        <>
          {selectedIndex > -1 ? (
            <button
              className="custom-button btn-dark submit-button"
              onClick={() => setModalOpen(true)}
            >
              Go to Confirmation
            </button>
          ) : (
            <b>Select a tier to get started</b>
          )}
          {selectedIndex > -1 && (
            <ConfirmationModal
              isOpen={modalOpen}
              onClose={setModalOpen}
              selectedTier={tiers[selectedIndex]}
              onConfirm={onConfirm}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Tiers;
