import React from "react";

type SecondaryHeaderProps = {
  text: any;
  align?: string;
};

const SecondaryHeader = ({ text, align }: SecondaryHeaderProps) => {
  return (
    <div className={`text-${align || "center"}`}>
      <h6>
        <b>
          <u>{text}</u>
        </b>
      </h6>
    </div>
  );
};

export default SecondaryHeader;
