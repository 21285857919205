export const tokenKey = "auth-token";
export function titleCase(text: string) {
  if (!text) return "";
  let textArray: string[] = text.split(" ");
  let resultArray: string[] = [];
  for (let word of textArray) {
    resultArray.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  }
  return resultArray.join(" ");
}
export function convertStripeCurrency(
  amount: number,
  includeDecimals: boolean | undefined = false
): string {
  return `$${(amount / 100).toFixed(includeDecimals ? 2 : 0)}`;
}
export const stripePublicKey =
  process.env.NODE_ENV !== "production"
    ? "pk_test_51Kb5EXA3ulGLkZBNcg8QUKAe90FNaz0tslB06DvupIRiqikb3PpZDPWPAnMzbvDr7iXYQ6qOKak187HpJLQFTgtW00fZ3kICeK"
    : "";

export const longMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function convertTimestamp(timestamp: number, shortName: boolean) {
  if (!timestamp) return "";
  const date = new Date(timestamp * 1000);
  let month = date.getMonth();
  return `${
    shortName ? shortMonthNames[month] : longMonthNames[month]
  } ${date.getDate()}, ${date.getFullYear()}`;
}
export function convertDate(date: Date | string | undefined): string {
  if (!date) return "";
  if (typeof date === "string") date = new Date(date);
  return date.toLocaleDateString();
}
export const units = [
  { id: "metric", label: "Metric" },
  { id: "imperial", label: "Imperial" },
];
export const tables = [{ id: "ASCE 7-16", label: "ASCE 7-16" }];

// for handling user inputs if type is number
export function convertNumberString(
  value: string,
  type: string,
  previousValue: string
): string {
  let returnValue = value;
  if (type === "number") {
    if (
      returnValue.length === 2 &&
      returnValue[0] === "0" &&
      !isNaN(Number(returnValue[1]))
    )
      returnValue = returnValue[1];
    if (isNaN(Number(returnValue)) && returnValue !== ".")
      returnValue = previousValue;
    else {
      if (
        returnValue.length > 1 &&
        returnValue[0] === "0" &&
        returnValue[1] !== "."
      )
        returnValue = previousValue;
      else if (returnValue[0] === ".") returnValue = "0" + returnValue;
    }
  }
  return returnValue;
}
