const mainBackground = "#e6ffe8";
const lightGrey = "#e6e6e6";
const darkGrey = "#999999";

export const popupStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "auto",
    backgroundColor: mainBackground,
    border: `1px solid ${darkGrey}`,
  },
};

export const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
    width: "90%",
    backgroundColor: lightGrey,
    border: `1px solid ${darkGrey}`,
  },
};
