import React, { useState, useContext } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

import BasicModal from "./../common/contentArea/basicModal";
import RadioSelector from "../common/form/radioSelector";
import { convertStripeCurrency } from "../../utils/allowables";
import CreditCardEntry from "./creditCardEntry";
import LoadingContext from "./../../context/loadingContext";
import UserContext from "./../../context/userContext";
import Switch from "./../common/form/switch";
import Input from "../common/form/input";
import { createPaymentMethod } from "../../utils/subscriptions";

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: Function;
  selectedTier: any;
  onConfirm: Function;
};

const ConfirmationModal = ({
  isOpen,
  onClose,
  selectedTier,
  onConfirm,
}: ConfirmationModalProps) => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const { customer } = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCycle, setSelectedCycle] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [useDefaultPaymentMethod, setUseDefaultPaymentMethod] = useState(false);

  const buttons = [
    {
      name: "monthly",
      label:
        "Monthly - " +
        convertStripeCurrency(
          selectedTier.prices.find((p: any) => p.recurring.interval === "month")
            .unit_amount
        ),
    },
    {
      name: "yearly",
      label:
        "Yearly - " +
        convertStripeCurrency(
          selectedTier.prices.find((p: any) => p.recurring.interval === "year")
            .unit_amount
        ),
    },
  ];

  const handleCancel = () => {
    onClose(false);
    setSelectedCycle("");
  };

  const handleConfirm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    if (!stripe || !elements) {
      toast.error("Could not load payments API. Please try again later.");
    } else {
      let paymentMethodID = "default";
      if (!useDefaultPaymentMethod) {
        const paymentMethod: any = await createPaymentMethod(stripe, elements);
        if (paymentMethod.error) {
          setLoading(false);
          return toast.error(paymentMethod.error);
        } else paymentMethodID = paymentMethod.id;
      }

      return onConfirm(
        paymentMethodID,
        selectedTier.prices.find(
          (p: any) => p.recurring.interval === selectedCycle.slice(0, -2)
        ).id,
        discountCode
      );
    }
    setLoading(false);
  };

  return (
    <BasicModal isOpen={isOpen} onClose={() => handleCancel()}>
      <h5>
        <b>{selectedTier.name}</b>
      </h5>
      <RadioSelector
        header="Select Billing Cycle"
        buttons={buttons}
        selected={selectedCycle}
        onChange={(value) => setSelectedCycle(value)}
      />
      <br />
      <Input
        name="discountCode"
        value={discountCode}
        label="Discount Code"
        onChange={(value) => setDiscountCode(value.toUpperCase())}
        type="text"
        tooltip={{
          content: (
            <>
              <b>Discount Code</b>
              <br />
              If you have a discount code for a free trial enter it here.
              <br />
              Codes can only be applied to first time subscribers.
            </>
          ),
          direction: "top",
        }}
        boldHeader={true}
      />
      <br />
      <br />
      {selectedCycle && (
        <form onSubmit={handleConfirm}>
          {!useDefaultPaymentMethod && <CreditCardEntry />}
          <br />
          {customer &&
            customer.defaultCard &&
            customer.defaultCard.card &&
            customer.defaultCard.card.last4 && (
              <>
                <Switch
                  name="useDefaultPaymentMethod"
                  value={useDefaultPaymentMethod}
                  onChange={setUseDefaultPaymentMethod}
                  label={`Use default card ending in ${customer.defaultCard.card.last4}`}
                />
              </>
            )}
          <br />
          <button className="custom-button btn-dark submit-button">
            Confirm Subscription
          </button>
        </form>
      )}
    </BasicModal>
  );
};

export default ConfirmationModal;
