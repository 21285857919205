import React from "react";
import { IUser } from "../types/userTypes";

interface IUserContext {
  user: IUser | null;
  setUser: Function;
  customer: any;
  setCustomer: Function;
}

const UserContext = React.createContext<IUserContext>({
  user: null,
  setUser: (user: IUser | null) => {},
  customer: null,
  setCustomer: (customer: any) => {},
});
export default UserContext;
