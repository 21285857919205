import { toast } from "react-toastify";

import { IData } from "../types/generics";
import { interpolate, getThreshholdValue } from "./projectsUtil";
import { ISeismicInfo } from "../types/projectTypes";

export function calculateSeismicData(
  updatedSeismicInfo: IData,
  effectiveHeight: number,
  table: IData | null,
  lateralSystems: IData[] | null
) {
  if (!table || !lateralSystems) {
    toast.error("Choose code book to make calculations");
    return updatedSeismicInfo;
  }

  const siteCoefficientShort =
    updatedSeismicInfo.siteClass === "f"
      ? 0
      : interpolate(
          updatedSeismicInfo.sraShort,
          table.shortPeriodSiteCoefficient[updatedSeismicInfo.siteClass],
          "ss",
          "coefficient"
        );
  const siteCoefficientOne =
    updatedSeismicInfo.siteClass === "f"
      ? 0
      : interpolate(
          updatedSeismicInfo.sraOne,
          table.oneSecondPeriodSiteCoefficient[updatedSeismicInfo.siteClass],
          "s1",
          "coefficient"
        );
  updatedSeismicInfo.siteCoefficientShort = siteCoefficientShort;
  updatedSeismicInfo.siteCoefficientOne = siteCoefficientOne;

  updatedSeismicInfo.mceSraShort =
    Number(updatedSeismicInfo.sraShort) * siteCoefficientShort;
  updatedSeismicInfo.mceSraOne =
    Number(updatedSeismicInfo.sraOne) * siteCoefficientOne;

  updatedSeismicInfo.sraShortSds = (updatedSeismicInfo.mceSraShort / 3) * 2;
  updatedSeismicInfo.sraOneSd1 = (updatedSeismicInfo.mceSraOne / 3) * 2;

  updatedSeismicInfo.riskImportanceFactor = table.riskImportanceFactors[
    updatedSeismicInfo.riskCategory
  ].find((r: any) => r.factor.includes("seismic")).importance;

  const designCategoryShort = getThreshholdValue(
    updatedSeismicInfo.sraShort,
    table.seismicDesignShort[updatedSeismicInfo.riskCategory],
    "sds",
    "category"
  );
  const designCategoryOne = getThreshholdValue(
    updatedSeismicInfo.sraShort,
    table.seismicDesignShort[updatedSeismicInfo.riskCategory],
    "sds",
    "category"
  );
  const options = ["a", "b", "c", "d"];
  const dcShortIndex = options.findIndex((i) => i === designCategoryShort);
  const dcLongIndex = options.findIndex((i) => i === designCategoryOne);
  updatedSeismicInfo.designCategory =
    updatedSeismicInfo.siteClass === "f"
      ? "d"
      : options[Math.max(dcShortIndex, dcLongIndex)] || "d";

  const forceResistingSystem = {
    x: lateralSystems.find(
      (l) => l.Label === updatedSeismicInfo.xForceResistingSystem
    ),
    y: lateralSystems.find(
      (l) => l.Label === updatedSeismicInfo.yForceResistingSystem
    ),
  };
  updatedSeismicInfo.responseModificationFactor = {
    x: forceResistingSystem.x?.R || 0,
    y: forceResistingSystem.y?.R || 0,
  };

  updatedSeismicInfo.systemOverstrengthFactor = {
    x: forceResistingSystem.x?.omega || 0,
    y: forceResistingSystem.y?.omega || 0,
  };
  updatedSeismicInfo.deflectionAmplificationFactor = {
    x: forceResistingSystem.x?.Cd || 0,
    y: forceResistingSystem.y?.Cd || 0,
  };
  updatedSeismicInfo.periodParameterCt = {
    x: forceResistingSystem.x?.Ct || 0,
    y: forceResistingSystem.y?.Ct || 0,
  };
  updatedSeismicInfo.periodParameterX = {
    x: forceResistingSystem.x?.x || 0,
    y: forceResistingSystem.y?.x || 0,
  };

  updatedSeismicInfo.periodUpperLimitCoefficient = {
    x: calcPeriodUpperLimitCoefficient(updatedSeismicInfo, table, "x"),
    y: calcPeriodUpperLimitCoefficient(updatedSeismicInfo, table, "y"),
  };

  updatedSeismicInfo.approxBuildingPeriod = {
    x: calcApproxBuildingPeriod(updatedSeismicInfo, effectiveHeight, "x"),
    y: calcApproxBuildingPeriod(updatedSeismicInfo, effectiveHeight, "y"),
  };
  updatedSeismicInfo.designPeriodUpperLimit = {
    x: calcDesignPeriodUpperLimit(updatedSeismicInfo, "x"),
    y: calcDesignPeriodUpperLimit(updatedSeismicInfo, "y"),
  };
  updatedSeismicInfo.calculatedSeismicResponseCoefficient = {
    x: calcCalculatedSeismicResponseCoefficient(updatedSeismicInfo, "x"),
    y: calcCalculatedSeismicResponseCoefficient(updatedSeismicInfo, "y"),
  };
  updatedSeismicInfo.csNeedNotExceed = {
    x: calcCsNotExceed(updatedSeismicInfo, "x"),
    y: calcCsNotExceed(updatedSeismicInfo, "y"),
  };
  updatedSeismicInfo.csShallNotBeLessThan = {
    x: calcCsShallNotBeLessThan(updatedSeismicInfo, "x"),
    y: calcCsShallNotBeLessThan(updatedSeismicInfo, "y"),
  };
  updatedSeismicInfo.designSeismicResponseCoefficient = {
    x: calcDesignSeismicResponseCoefficient(updatedSeismicInfo, "x"),
    y: calcDesignSeismicResponseCoefficient(updatedSeismicInfo, "y"),
  };

  return updatedSeismicInfo;
}

function calcCalculatedSeismicResponseCoefficient(
  seismicInfo: ISeismicInfo,
  xy: string
) {
  return handleDivZeroError(
    seismicInfo.sraShortSds /
      (seismicInfo.responseModificationFactor.x /
        Number(seismicInfo.riskImportanceFactor))
  );
}
function calcCsNotExceed(seismicInfo: ISeismicInfo, xy: string) {
  if (
    seismicInfo.approxBuildingPeriod[xy] <=
    Number(seismicInfo.longPeriodTransition)
  ) {
    return handleDivZeroError(
      seismicInfo.sraOneSd1 /
        (seismicInfo.approxBuildingPeriod[xy] *
          (seismicInfo.responseModificationFactor[xy] /
            seismicInfo.riskImportanceFactor))
    );
  } else {
    return (
      (seismicInfo.sraOneSd1 * Number(seismicInfo.longPeriodTransition)) /
      (seismicInfo.approxBuildingPeriod[xy] ** 2 *
        (seismicInfo.approxBuildingPeriod[xy] /
          seismicInfo.riskImportanceFactor))
    );
  }
}

function calcPeriodUpperLimitCoefficient(
  seismicInfo: ISeismicInfo,
  table: IData,
  xy: string
) {
  return interpolate(
    seismicInfo.sraOneSd1,
    table.coefficientForUpperLimit.cu,
    "sd1",
    "coefficient"
  );
}
function calcApproxBuildingPeriod(
  seismicInfo: ISeismicInfo,
  effectiveHeight: number,
  xy: string
) {
  return (
    effectiveHeight ** seismicInfo.periodParameterX[xy] *
    seismicInfo.periodParameterCt[xy]
  );
}
function calcDesignPeriodUpperLimit(seismicInfo: ISeismicInfo, xy: string) {
  return (
    seismicInfo.approxBuildingPeriod[xy] *
    seismicInfo.periodUpperLimitCoefficient[xy]
  );
}
function calcCsShallNotBeLessThan(seismicInfo: ISeismicInfo, xy: string) {
  return Math.max(
    0.044 * seismicInfo.sraShortSds * seismicInfo.riskImportanceFactor,
    0.01
  );
}
function calcDesignSeismicResponseCoefficient(
  seismicInfo: ISeismicInfo,
  xy: string
) {
  return Math.max(
    Math.min(
      seismicInfo.calculatedSeismicResponseCoefficient[xy],
      seismicInfo.csNeedNotExceed[xy]
    ),
    seismicInfo.csShallNotBeLessThan[xy]
  );
}

function handleDivZeroError(value: number | string) {
  if (isNaN(Number(value))) return 0;
  return value;
}
