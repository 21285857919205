import React from "react";

import Input from "../common/form/input";
import TextArea from "../common/form/textArea";
import { IData, IInput } from "./../../types/generics";

type ContactUsProps = {
  formData: IData;
  onSubmitForm: Function;
  setFormData: Function;
};

const ContactUs = ({ formData, setFormData, onSubmitForm }: ContactUsProps) => {
  const handleChange = (property: string, value: string) => {
    let newData = { ...formData };
    newData[property] = value;
    setFormData(newData);
  };

  const inputs: IInput[] = [
    { name: "name", label: "Name", type: "text" },
    { name: "email", label: "Email", type: "email" },
    { name: "subject", label: "Subject", type: "text" },
  ];

  return (
    <div className="text-center">
      {inputs.map((input: IInput) => (
        <Input
          key={input.name}
          type={input.type}
          name={input.name}
          label={input.label}
          value={formData[input.name]}
          onChange={(value) => handleChange(input.name, value)}
        />
      ))}
      <TextArea
        name="text"
        label="Enter Question"
        value={formData.text}
        onChange={(value: string) => handleChange("text", value)}
      />
      <br />
      <button
        className="custom-button btn-dark submit-button"
        onClick={() => onSubmitForm()}
      >
        Submit
      </button>
    </div>
  );
};

export default ContactUs;
