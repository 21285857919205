import React from "react";
import {
  IData,
  IInput,
  ISelectMap,
  ISelectOption,
} from "../../../types/generics";
import { titleCase } from "../../../utils/allowables";
import Select from "../../common/form/select";
import InputMap from "../../common/form/inputMap";
import Subscript from "../../common/text/subscript";
import TertiaryHeader from "../../common/text/tertiaryHeader";

type SeismicInputsProps = {
  seismicInfo: IData;
  onUpdateSeismicInfo: (property: string, value: string) => void;
  systemSelectOptions: ISelectOption[];
};

const SeismicInputs = ({
  seismicInfo,
  onUpdateSeismicInfo,
  systemSelectOptions,
}: SeismicInputsProps) => {
  const seismicInputs: IInput[] = [
    {
      name: "sraShort",
      label: (
        <Subscript
          preText="Spectral Response Acceleration, Short Period (S"
          subText="S"
          postText=")"
        />
      ),
      type: "number",
      step: 0.001,
      toFixed: 3,
    },
    {
      name: "sraOne",
      label: (
        <Subscript
          preText="Spectral Response Acceleration, 1-Sec. Period (S"
          subText="1"
          postText=")"
        />
      ),
      type: "number",
      step: 0.001,
    },
    {
      name: "longPeriodTransition",
      label: (
        <Subscript
          preText="Long Period Transition (T"
          subText="L"
          postText=")"
        />
      ),
      type: "number",
    },
  ];

  const seismicSelects: ISelectMap[] = [
    {
      name: "soilsReport",
      label: "Soils Report",
      options: [
        { id: "no", label: "No" },
        { id: "yes", label: "Yes" },
      ],
    },
    {
      name: "siteClass",
      label: "Site Class",
      options: ["a", "b", "c", "d", "e", "f"].map((v) => {
        return { id: v, label: titleCase(v) };
      }),
    },
    {
      name: "riskCategory",
      label: "Risk Category",
      options: ["i", "ii", "iii", "iv"].map((v) => {
        return { id: v, label: v.toUpperCase() };
      }),
    },
  ];

  return (
    <>
      <TertiaryHeader text="Inputs" />
      <div className="row">
        <div className="col">
          {seismicSelects.map((s, idx) => (
            <Select
              key={idx}
              name={s.name}
              label={s.label}
              options={s.options}
              selectedOption={seismicInfo[s.name]}
              onChange={(value: string) => onUpdateSeismicInfo(s.name, value)}
            />
          ))}
          <InputMap
            inputs={seismicInputs}
            onChange={onUpdateSeismicInfo}
            data={seismicInfo}
          />
        </div>
        <div className="col">
          {["x", "y"].map((c) => (
            <React.Fragment key={c}>
              <Select
                name="sfrsX"
                label={`Seismic Force Resisting System: ${c.toUpperCase()}`}
                options={systemSelectOptions}
                selectedOption={seismicInfo[`${c}ForceResistingSystem`]}
                onChange={(value: string) =>
                  onUpdateSeismicInfo(`${c}ForceResistingSystem`, value)
                }
                style={{
                  maxWidth: 500,
                  width: 500,
                  paddingRight: 10,
                }}
                valueBoxStyle={{ height: 45 }}
              />
            </React.Fragment>
          ))}
          <div className="row">
            {["x", "y"].map((c) => (
              <div className="col" key={c}>
                <Select
                  name="redundancy"
                  label={`Redundancy: ${c.toUpperCase()}`}
                  options={[
                    { id: "1", label: "1.0" },
                    { id: "1.3", label: "1.3" },
                  ]}
                  selectedOption={seismicInfo[`${c}Redundancy`]}
                  onChange={(value: string) =>
                    onUpdateSeismicInfo(`${c}Redundancy`, value)
                  }
                  style={{
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeismicInputs;
