import React from "react";

type MiniBrProps = {
  height?: number;
};

const MiniBr = ({ height }: MiniBrProps) => {
  return <div style={{ height: height || 5 }} />;
};

export default MiniBr;
