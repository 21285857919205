import React, { useEffect, useCallback } from "react";
import { IData } from "../../../types/generics";
import Icons from "../icons/icons";

type DateSearchProps = {
  dates: IData;
  setDates: (dates: IData) => void;
};

const DateSearch = ({ dates, setDates }: DateSearchProps) => {
  const handleClearDates = useCallback(() => {
    setDates({ from: "", to: "" });
  }, [setDates]);

  useEffect(() => {
    const escFunction = (e: any) => {
      if (e.keyCode === 27) handleClearDates();
    };
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [handleClearDates]);

  const handleChangeDate = (date: string, field: string) => {
    let newDates = { ...dates };
    newDates[field] = date;
    if (new Date(newDates.from) > new Date(newDates.to)) {
      if (field === "to") newDates.from = newDates.to;
      else newDates.to = newDates.from;
    }
    setDates(newDates);
  };

  return (
    <div className="text-center">
      <div className="row">
        <div className="col">
          From:{" "}
          <input
            type="date"
            name="query"
            value={dates.from}
            onChange={(e) => handleChangeDate(e.currentTarget.value, "from")}
            className="custom-input search"
          />
        </div>
        <div className="col">
          To:{" "}
          <input
            type="date"
            name="query"
            value={dates.to}
            onChange={(e) => handleChangeDate(e.currentTarget.value, "to")}
            className="custom-input search"
          />
        </div>
        <div style={{ position: "relative" }}>
          {(dates.from !== "" || dates.to !== "") && (
            <div
              className="clear-button clickable"
              onClick={() => handleClearDates()}
              style={{ right: 5, bottom: 2 }}
            >
              <Icons iconType="cancel" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateSearch;
