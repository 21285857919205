import React from "react";
import {
  AiFillCreditCard,
  AiFillEdit,
  AiFillSetting,
  AiFillDelete,
  AiFillFolderOpen,
  AiOutlineDownload,
} from "react-icons/ai";
import { BsArrowsMove } from "react-icons/bs";
import {
  FaCaretUp,
  FaCaretDown,
  FaUserCircle,
  FaProjectDiagram,
  FaStripeS,
  FaUndo,
  FaCalculator,
} from "react-icons/fa";
import { GiWeightCrush } from "react-icons/gi";
import {
  IoMdArrowRoundBack,
  IoMdSave,
  IoMdLogIn,
  IoMdLogOut,
  IoMdCheckboxOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import {
  MdHelp,
  MdAutoDelete,
  MdCancel,
  MdAddCircleOutline,
  MdFileCopy,
  MdSettingsInputHdmi,
} from "react-icons/md";
import { RiEarthquakeLine } from "react-icons/ri";
import { VscLayersActive, VscLayers } from "react-icons/vsc";
import { IconContext } from "react-icons";
import { IData } from "../../../types/generics";

type IconsProps = {
  iconType: string;
  className?: string;
};

const iconOptions: IData = {
  activeLayer: VscLayersActive,
  add: MdAddCircleOutline,
  arrows: BsArrowsMove,
  back: IoMdArrowRoundBack,
  calculate: FaCalculator,
  check: IoMdCheckboxOutline,
  cancel: MdCancel,
  copy: MdFileCopy,
  creditCard: AiFillCreditCard,
  delete: AiFillDelete,
  down: FaCaretDown,
  download: AiOutlineDownload,
  earthquake: RiEarthquakeLine,
  edit: AiFillEdit,
  help: MdHelp,
  info: IoMdInformationCircleOutline,
  input: MdSettingsInputHdmi,
  layers: VscLayers,
  login: IoMdLogIn,
  logout: IoMdLogOut,
  markDelete: MdAutoDelete,
  open: AiFillFolderOpen,
  project: FaProjectDiagram,
  save: IoMdSave,
  setting: AiFillSetting,
  stripe: FaStripeS,
  undo: FaUndo,
  up: FaCaretUp,
  user: FaUserCircle,
  weight: GiWeightCrush,
};

const needRaising = ["add", "undo", "delete", "save", "back"];

const Icons = ({ iconType, className = "" }: IconsProps) => {
  if (!iconOptions[iconType]) return null;
  if (needRaising.includes(iconType)) className += " icon-raised-mini";

  return (
    <IconContext.Provider value={{ className }}>
      {React.createElement(iconOptions[iconType])}
    </IconContext.Provider>
  );
};

export default Icons;
