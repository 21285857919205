import React from "react";
import { IData } from "../../../types/generics";

type UnitsProps = {
  text: string | number;
  units: string;
  type: string;
  noParentheses?: boolean;
};

const unitsHashMap: IData = {
  metric: {
    length: <>m</>,
    pressure: (
      <>
        kN/m<sup>2</sup>
      </>
    ),
    mass: <>kN</>,
    area: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  imperial: {
    length: <>ft</>,
    pressure: (
      <>
        lbs/ft<sup>2</sup>
      </>
    ),
    mass: <>klbs</>,
    area: (
      <>
        ft<sup>2</sup>
      </>
    ),
  },
};

const Units = ({ text, units, type, noParentheses }: UnitsProps) => {
  const unitText = unitsHashMap[units][type];
  if (!unitText) return <>{text}</>;
  return (
    <>
      {text} {noParentheses ? "" : " ("}
      {unitText}
      {noParentheses ? "" : ")"}
    </>
  );
};

export default Units;
