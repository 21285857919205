import { IData } from "../types/generics";
import { toast } from "react-toastify";

export const blankProjectInfo = {
  name: "",
  address: "",
  jobID: "",
  date: "",
  units: "imperial",
  table: "ASCE 7-16",
};

export const blankLevel = {
  name: "",
  height: 0,
  elevation: 0,
  width: 0,
  length: 0,
  horizontalMass: 0,
  verticalMass: 0,
};

export const blankDeadLoad = {
  name: "",
  totalWeight: 0,
  direction: "horizontal",
  levelName: "",
  superimposed: { deadLoads: [], totalWeight: 0 },
  selfWeight: { deadLoads: [], totalWeight: 0 },
  additional: { deadLoads: [], totalWeight: 0 },
  misc: { deadLoads: [], totalWeight: 0 },
  area: 0,
  horizontalMass: 0,
  verticalMass: 0,
};

export const blankWeightObject = {
  name: "",
  weight: 0,
};

export const blankSeismicData = {
  // input fields
  soilsReport: "no",
  siteClass: "a",
  sraShort: 0,
  sraOne: 0,
  riskCategory: "i",
  longPeriodTransition: 0,
  xForceResistingSystem: "",
  yForceResistingSystem: "",
  xRedundancy: "1.3",
  yRedundancy: "1.3",
  // calculated fields
  siteCoefficientShort: 0,
  siteCoefficientOne: 0,
  mceSraShort: 0,
  mceSraOne: 0,
  sraShortSds: 0,
  sraOneSd1: 0,
  riskImportanceFactor: 0,
  designCategory: "a",
  responseModificationFactor: { x: 0, y: 0 },
  systemOverstrengthFactor: { x: 0, y: 0 },
  deflectionAmplificationFactor: { x: 0, y: 0 },
  periodParameterCt: { x: 0, y: 0 },
  periodParameterX: { x: 0, y: 0 },
  periodUpperLimitCoefficient: { x: 0, y: 0 },
  approxBuildingPeriod: { x: 0, y: 0 },
  designPeriodUpperLimit: { x: 0, y: 0 },
  calculatedSeismicResponseCoefficient: { x: 0, y: 0 },
  csNeedNotExceed: { x: 0, y: 0 },
  csShallNotBeLessThan: { x: 0, y: 0 },
  designSeismicResponseCoefficient: { x: 0, y: 0 },
};

export const deadLoadTypes = ["superimposed", "selfWeight", "additional"];

export function calculateHorizontalMass(area: number, totalWeight: number) {
  return (area * totalWeight) / 1000;
}
export function calculateVerticalMass(
  area: number,
  totalWeight: number,
  height: number
) {
  console.log(height, area, totalWeight);
  return (height * Number(area) * totalWeight) / 1000;
}

export function interpolate(
  x: number,
  row: any[],
  index: string,
  valueProperty: string
) {
  if (!x) return 0;
  let y = row.find((r) => r[index] === Number(x));
  if (!y) {
    const [x1, x2] = findClosestValues(
      row.map((r) => r[index]),
      Number(x)
    );
    if (!x1 || !x2) return "Invalid Entry";
    const y1 = row.find((r) => r[index] === x1)[valueProperty];
    const y2 = row.find((r) => r[index] === x2)[valueProperty];
    return y1 + (x - x1) * ((y2 - y1) / (x2 - x1));
  } else return y[valueProperty];
}

function findClosestValues(array: number[], value: number): number[] {
  let result: number[] = [];
  array.some((a) => {
    if (a > value) return result.push(a);
    result = [a];
    return a === value;
  });
  return result;
}

export function getThreshholdValue(
  x: number,
  row: any[],
  index: string,
  valueProperty: string
): string {
  if (!x) return "a";
  let colIndex = 0;
  row.every((r, idx) => {
    if (Number(x) > r[index]) {
      colIndex = idx;
      return true;
    }
    return false;
  });
  return colIndex === row.length - 1
    ? row[colIndex][valueProperty]
    : row[colIndex + 1][valueProperty];
}
