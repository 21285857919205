import React from "react";

type VertSpaceProps = {
  spaces?: number;
};

const VertSpace = ({ spaces = 5 }: VertSpaceProps) => {
  const mappable = new Array(spaces).fill(null);
  return (
    <>
      {mappable.map((m, i) => (
        <React.Fragment key={i}>&nbsp;</React.Fragment>
      ))}
    </>
  );
};

export default VertSpace;
