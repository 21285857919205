import React from "react";

interface IRadioButton {
  name: string;
  label: string;
  tooltip?: {
    content: any;
    direction?: string;
  };
}

type InputProps = {
  header: string;
  buttons: IRadioButton[];
  selected: string | number;
  onChange: (event: string) => void;
};

const RadioSelector = ({ header, buttons, selected, onChange }: InputProps) => {
  return (
    <>
      <b>{header}</b>
      <br />
      {buttons.map((b) => (
        <React.Fragment key={b.name}>
          <label htmlFor={b.name} className="radio-container">
            <input
              className="radio-button"
              type="radio"
              id={b.name}
              name={b.name}
              value={b.name}
              onChange={(event) => onChange(event.currentTarget.value)}
              checked={b.name === selected}
              data-testid="custom-radio-button"
            />
            <span className="radio-button"></span>
            &nbsp;
            {b.label}
          </label>
        </React.Fragment>
      ))}
    </>
  );
};

export default RadioSelector;
