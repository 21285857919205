import React from "react";

import Tooltip from "./../tooltip/tooltip";
import { convertNumberString } from "../../../utils/allowables";

type InputProps = {
  name: string;
  label: string;
  value: string | number;
  type: string;
  min?: number;
  max?: number;
  step?: number;
  onChange: (event: string) => void;
  tooltip?: {
    content: any;
    direction?: string;
  };
  usePasswordTooltip?: boolean;
  boldHeader?: boolean;
  autofocus?: boolean;
  style?: any;
  disabled?: boolean;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  hideLabel?: boolean;
  table?: boolean;
  placeholder?: string;
  toFixed?: number;
};

const Input = ({
  name,
  label,
  value,
  onChange,
  type,
  min,
  max,
  step,
  tooltip,
  usePasswordTooltip,
  boldHeader,
  autofocus,
  style,
  disabled,
  forwardedRef,
  hideLabel,
  table,
  placeholder,
  toFixed,
}: InputProps) => {
  const finalLabel = boldHeader ? <b>{label}</b> : label;

  const passwordTooltip = {
    content: (
      <>
        <b>Password Requirements</b>
        <br />
        Minimum 8 characters long
        <br />
        One lower case
        <br />
        One upper case
        <br />
        One number
        <br />
        One symbol
      </>
    ),
    direction: "top",
  };
  return (
    <div className={table ? "" : "input-container"}>
      {!hideLabel ? (
        tooltip || usePasswordTooltip ? (
          <Tooltip
            content={
              usePasswordTooltip ? passwordTooltip.content : tooltip!.content
            }
            direction={
              usePasswordTooltip
                ? passwordTooltip.direction
                : tooltip!.direction
            }
          >
            <label htmlFor={name}>{finalLabel}</label>
          </Tooltip>
        ) : (
          <div>
            <label htmlFor={name}>{finalLabel}</label>
          </div>
        )
      ) : null}
      <input
        ref={forwardedRef}
        className="custom-input"
        value={value}
        onChange={(event) =>
          onChange(
            convertNumberString(
              event.currentTarget.value,
              type,
              value as string
            )
          )
        }
        type={["password", "date"].includes(type) ? type : ""}
        min={min}
        max={max}
        step={step}
        data-testid="custom-input"
        style={style}
        disabled={disabled}
        autoFocus={autofocus}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
