import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ProfileHeader from "./profileHeader";
import TabbedArea from "../../common/contentArea/tabbedArea";
import ProfileEdit from "./profileEdit";
import ProfileSettings from "./profileSettings";
import Subscription from "../../subscription/subscription";
import { IData } from "../../../types/generics";
import {
  updateUser,
  updateUserSettings,
  getCurrentUser,
  deleteUser,
  refreshUser,
} from "../../../services/userService";
import { IRegister } from "../../../services/authService";
import { getTiers, getCustomer } from "../../../services/subscriptionService";
import LoadingContext from "../../../context/loadingContext";
import UserContext from "../../../context/userContext";
import { ISettings } from "../../../types/userTypes";

const Profile = () => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const { user, setUser, setCustomer } = useContext(UserContext);
  let navigate = useNavigate();
  const tabs = ["subscription", "edit my info", "settings"];
  const icons = ["creditCard", "edit", "setting"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tiers, setTiers] = useState([]);

  const loadData = useCallback(async () => {
    const numOfRequests = 3;
    setLoading(true);
    setProgress(1);
    await refreshUser(async (p) => setProgress(p / numOfRequests));
    const tiersRes = await getTiers(async (p) =>
      setProgress(p / numOfRequests)
    );
    const customerRes = await getCustomer(async (p) =>
      setProgress(p / numOfRequests)
    );
    if (tiersRes.status === 200) {
      setTiers(tiersRes.data);
    } else toast.error(tiersRes.data);
    if (customerRes.status === 200) {
      setCustomer(customerRes.data);
    }

    setLoading(false);
  }, [setLoading, setProgress, setTiers, setCustomer]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmitEdit = async (
    event: React.FormEvent<HTMLFormElement>,
    data: IData
  ) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    const res = await updateUser(data as IRegister, async (p) =>
      setProgress(p)
    );
    if (res.status === 200) {
      setUser(getCurrentUser());
      toast.success("Updated");
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleUpdateSettings = async (
    event: React.FormEvent<HTMLFormElement>,
    data: IData
  ) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    const res = await updateUserSettings(data as ISettings, async (p) =>
      setProgress(p)
    );
    if (res.status === 200) {
      setUser(getCurrentUser);
      toast.success("Settings Updated");
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    setProgress(1);
    const res = await deleteUser(navigate, async (p) => setProgress(p));
    if (res && res.status === 200) {
      toast.success("Account deleted");
      setUser(null);
      setCustomer(null);
    } else toast.error(res.data);

    setLoading(false);
  };

  return (
    <>
      <ProfileHeader />
      <TabbedArea
        tabPlacement="top"
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
        icons={icons}
      >
        <div>
          {selectedTab === "edit my info" ? (
            <ProfileEdit onSubmit={handleSubmitEdit} />
          ) : selectedTab === "settings" ? (
            <ProfileSettings
              settings={user?.settings}
              onDeleteUser={handleDeleteUser}
              onUpdateSettings={handleUpdateSettings}
            />
          ) : selectedTab === "subscription" ? (
            <Subscription tiers={tiers} refreshProfile={loadData} />
          ) : null}
        </div>
      </TabbedArea>
    </>
  );
};

export default Profile;
