import React from "react";
import { IInput } from "../../../types/generics";
import Input from "./input";

import Icons from "../icons/icons";

type InputMapProps = {
  inputs: IInput[];
  data: any;
  onChange: (property: string, value: string) => void;
  mapHorizontal?: boolean;
  onReorder?: (index: number, direction: string) => void;
  index?: number;
  length?: number;
  onDelete?: (index: number) => void;
};

const InputMap = ({
  inputs,
  data,
  onChange,
  mapHorizontal,
  onReorder,
  index,
  length,
  onDelete,
}: InputMapProps) => {
  const hasIndex = index || index === 0;
  return (
    <div className={mapHorizontal ? "row" : ""}>
      {onDelete && hasIndex && (
        <div className="custom-input-map-buttons-button">
          <button
            className="custom-button btn-sm btn-light"
            onClick={() => onDelete(index!)}
          >
            <Icons iconType="delete" />
          </button>
        </div>
      )}
      {inputs.map((input: IInput, idx: number) => (
        <div className={mapHorizontal ? "col" : ""} key={idx}>
          <Input
            key={input.name}
            name={input.name}
            label={input.label}
            value={data[input.name]}
            type={input.type}
            onChange={(value) => onChange(input.name, value)}
            tooltip={input.tooltip}
            usePasswordTooltip={input.usePasswordTooltip}
            style={input.setWidth ? { width: input.setWidth } : {}}
            disabled={input.disabled}
            autofocus={input.autofocus}
            step={input.step}
            toFixed={input.toFixed}
          />
        </div>
      ))}
      {onReorder && hasIndex && length ? (
        <div className="custom-input-map-buttons-icon">
          {index > 0 ? (
            <div className="clickable" onClick={() => onReorder(index!, "up")}>
              <Icons iconType="up" />
            </div>
          ) : (
            <div style={{ height: 25 }} />
          )}
          {index < length - 1 && (
            <div
              className="clickable"
              onClick={() => onReorder(index!, "down")}
            >
              <Icons iconType="down" />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default InputMap;
