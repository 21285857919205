import React, { useState, useEffect, useContext } from "react";

import Input from "../../common/form/input";
import { IInput, IData } from "../../../types/generics";
import UserContext from "../../../context/userContext";

type ProfileEditProps = {
  onSubmit: Function;
};

const ProfileEdit = ({ onSubmit }: ProfileEditProps) => {
  const { user } = useContext(UserContext);
  const [data, setData] = useState<IData>({
    name: "",
  });
  useEffect(() => {
    const mapValues = () => {
      setData({
        name: (user && user.name) || "",
      });
    };
    mapValues();
  }, [user]);

  const inputs: IInput[] = [{ name: "name", label: "Name", type: "text" }];

  const handleChange = (property: string, value: string) => {
    let newData = { ...data };
    newData[property] = value;
    setData(newData);
  };

  return (
    <div className="row">
      <div className="col text-center">
        <b>My Information</b>
        <form onSubmit={(e) => onSubmit(e, data)}>
          {inputs.map((input) => (
            <Input
              key={input.name}
              name={input.name}
              label={input.label}
              value={data[input.name]}
              type={input.type}
              onChange={(value) => handleChange(input.name, value)}
              tooltip={input.tooltip}
            />
          ))}
          <br />
          <button className="custom-button btn-dark submit-button">
            Update
          </button>
        </form>
      </div>
      <div className="col text-center">
        <b>Image</b>
      </div>
    </div>
  );
};

export default ProfileEdit;
