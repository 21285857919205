import React, { useState, useContext, useEffect, useMemo } from "react";

import { sendContactUsEmail, IContactEmail } from "../../services/emailService";
import TabbedArea from "../common/contentArea/tabbedArea";
import ContactUs from "./contactUs";
import Faqs from "./faqs";
import LoadingContext from "./../../context/loadingContext";
import { IData } from "./../../types/generics";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../services/userService";

const Help = () => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const tabs = ["faqs", "contact us"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const blankForm = useMemo(() => {
    return {
      name: "",
      email: "",
      subject: "",
      text: "",
    };
  }, []);
  const [formData, setFormData] = useState<IData>(blankForm);

  useEffect(() => {
    const user = getCurrentUser();
    if (user)
      setFormData({
        ...blankForm,
        name: user.name,
        email: user.email,
      });
  }, [blankForm]);

  const handleSubmitForm = async () => {
    setLoading(true);
    setProgress(1);
    const res = await sendContactUsEmail(formData as IContactEmail, async (p) =>
      setProgress(p)
    );
    if (res.status === 200) {
      toast.success("Email sent");
      setFormData(blankForm);
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <TabbedArea
      tabPlacement="top"
      tabs={tabs}
      selectedTab={selectedTab}
      onSelectTab={setSelectedTab}
    >
      {selectedTab === "faqs" ? (
        <Faqs />
      ) : selectedTab === "contact us" ? (
        <ContactUs
          formData={formData}
          setFormData={setFormData}
          onSubmitForm={handleSubmitForm}
        />
      ) : null}
    </TabbedArea>
  );
};

export default Help;
