import React from "react";

type SwitchProps = {
  value: boolean;
  onChange: Function;
  label: string;
  name: string;
};

const Switch = ({ value, onChange, label, name }: SwitchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(!value, name);
  };

  return (
    <div className="text-center">
      {label}
      <br />
      <label className="switch">
        <input
          type="checkbox"
          onChange={(event) => handleChange(event)}
          checked={value}
        />
        <span className="slider" data-testid="custom-switch"></span>
      </label>
    </div>
  );
};

export default Switch;
