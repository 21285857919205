import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { NavDropdown, Navbar } from "react-bootstrap";

import { logout } from "../../services/authService";
import MiniPopup from "../common/contentArea/miniPopup";
import { titleCase } from "../../utils/allowables";
import LoadingContext from "./../../context/loadingContext";
import UserContext from "../../context/userContext";
import Icons from "../common/icons/icons";

const MainNavbar = () => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const { user, setUser } = useContext(UserContext);
  let navigate = useNavigate();
  let location = useLocation();
  const [header, setHeader] = useState<string>("");

  useEffect(() => {
    setHeader(titleCase(location.pathname.slice(1)));
  }, [location.pathname, user]);

  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  const logoutUser = async () => {
    setProgress(0);
    setLoading(true);
    setUser(null);
    await logout(navigate);
    setLoading(false);
  };

  const renderLogo = () => {
    return (
      <img
        alt="StructureMate"
        className="header-logo"
        src={"/logos/StructureMateTextOnlyNexaRustInverted.png"}
      />
    );
  };

  return (
    <>
      <nav className="navbar nav-tabs custom-navbar">
        <Navbar.Brand className="navbar-title">{renderLogo()}</Navbar.Brand>
        {user ? (
          <>
            <Navbar.Brand className="navbar-title">
              <b>{header}</b>
            </Navbar.Brand>
            {/* <NavLink
              to="/projects"
              className={({ isActive }) =>
                "custom-nav-link" +
                (isActive ? " custom-nav-link-selected" : "")
              }
            >
              Projects
            </NavLink> */}
            <NavDropdown
              title={
                <i className="username">
                  {user.name.split(" ")[0]}{" "}
                  <Icons iconType="setting" className="icon-raised icon-nav" />
                </i>
              }
            >
              <NavDropdown.Item
                className="nav-item"
                onClick={() => navigate("/profile")}
              >
                <Icons iconType="user" className="icon-raised icon-nav" />{" "}
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-item"
                onClick={() => navigate("/projects")}
              >
                <Icons iconType="project" className="icon-raised icon-nav" />{" "}
                Projects
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-item"
                onClick={() => navigate("/help")}
              >
                <Icons iconType="help" className="icon-raised icon-nav" /> Help
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-item"
                onClick={() => setLogoutOpen(true)}
              >
                <Icons iconType="logout" className="icon-raised icon-nav" />{" "}
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </>
        ) : (
          <div className="pad-in">
            <NavLink
              to="/help"
              className={({ isActive }) =>
                "custom-nav-link" +
                (isActive ? " custom-nav-link-selected" : "")
              }
            >
              <Icons iconType="help" className="icon-raised icon-nav" /> Help
            </NavLink>
            <NavLink
              to="/register"
              className={({ isActive }) =>
                "custom-nav-link" +
                (isActive ? " custom-nav-link-selected" : "")
              }
            >
              <Icons iconType="user" className="icon-raised icon-nav" />{" "}
              Register
            </NavLink>
            <NavLink
              to="/login"
              className={({ isActive }) =>
                "custom-nav-link" +
                (isActive ? " custom-nav-link-selected" : "")
              }
            >
              <Icons iconType="login" className="icon-raised icon-nav" /> Login
            </NavLink>
          </div>
        )}
      </nav>
      <MiniPopup
        type="confirm"
        onCancel={() => setLogoutOpen(false)}
        onConfirm={() => logoutUser()}
        isOpen={logoutOpen}
      >
        <b>Are you sure you want to logout?</b>
        <br />
        <br />
        Any unsaved work will be lost
      </MiniPopup>
    </>
  );
};

export default MainNavbar;
