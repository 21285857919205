import React, { useContext, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

import {
  convertStripeCurrency,
  titleCase,
  convertTimestamp,
  longMonthNames,
} from "../../utils/allowables";
import UserContext from "./../../context/userContext";
import LoadingContext from "../../context/loadingContext";
import MiniPopup from "../common/contentArea/miniPopup";
import CreditCardEntry from "./creditCardEntry";
import { createPaymentMethod } from "../../utils/subscriptions";

type CurrentSubscriptionProps = {
  tiers: any[];
  onRemovePaymentMethod: Function;
  onCancelSubscription: Function;
  onAddPaymentMethod: Function;
  onContinueSubscription: Function;
};

const CurrentSubscription = ({
  tiers,
  onRemovePaymentMethod,
  onCancelSubscription,
  onAddPaymentMethod,
  onContinueSubscription,
}: CurrentSubscriptionProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { customer } = useContext(UserContext);
  const { setLoading, setProgress } = useContext(LoadingContext);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [continueSubscriptionOpen, setContinueSubscriptionOpen] =
    useState(false);

  const [addPaymentMethodOpen, setAddPaymentMethodOpen] = useState(false);
  const subscription =
    customer && customer.subscriptions && customer.subscriptions.data[0];
  const defaultCard =
    customer && customer.defaultCard && customer.defaultCard.card;

  if (!subscription || tiers.length === 0)
    return (
      <>
        <b>No current subscription</b>
        <br />
        Subscribe to be able to save your projects
      </>
    );

  const confirmPopups = [
    {
      name: "remove",
      onCancel: setRemoveOpen,
      onConfirm: onRemovePaymentMethod,
      isOpen: removeOpen,
      header: "remove the payment method from your account",
      text: "cancel at",
    },
    {
      name: "cancel",
      onCancel: setCancelOpen,
      onConfirm: onCancelSubscription,
      isOpen: cancelOpen,
      header: "cancel your subscription",
      text: "cancel at",
    },
    {
      name: "continue",
      onCancel: setContinueSubscriptionOpen,
      onConfirm: onContinueSubscription,
      isOpen: continueSubscriptionOpen,
      header: "continue your subscription",
      text: "continue from",
    },
  ];

  let price: any;
  const product = tiers.find((t: any) => {
    price = t.prices.find(
      (p: any) => p.id === subscription.items.data[0].price.id
    );
    return price;
  });

  const handleGetPaymentMethod = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    const paymentMethod = await createPaymentMethod(stripe, elements);
    if (paymentMethod.error) {
      setLoading(false);
      return toast.error(paymentMethod.error);
    }
    setAddPaymentMethodOpen(false);
    onAddPaymentMethod(paymentMethod.id);
  };

  const cardExpiresSoonMessage = () => {
    if (!defaultCard) return "";
    const differenceDays =
      (new Date(defaultCard.exp_year, defaultCard.exp_month, 1).getTime() -
        new Date().getTime()) /
      1000 /
      60 /
      60 /
      24;

    if (differenceDays > 45) return "";

    return (
      <>
        Your default card{" "}
        {differenceDays < -31 ? "has expired" : "will expire soon"}.<br />
        Please update your payment method to continue uninterrupted service.
      </>
    );
  };

  const expired = cardExpiresSoonMessage();

  return (
    <div>
      {expired !== "" ? <div className="custom-alert">{expired}</div> : null}
      <b>{product.name}</b>
      <br />
      <b>Billing:</b> {titleCase(price.recurring.interval)}ly at{" "}
      {convertStripeCurrency(price.unit_amount)}
      <br />
      <b>
        {subscription.status === "trialing"
          ? "Free Trial Ends"
          : "Next Invoice"}
        :
      </b>{" "}
      {subscription.cancel_at_period_end && subscription.status !== "trialing"
        ? "n/a"
        : convertTimestamp(subscription.current_period_end, true)}
      <br />
      <b>Payment Method:</b>{" "}
      {defaultCard
        ? `${titleCase(defaultCard.brand)} ending ${defaultCard.last4}`
        : "No payment method attached"}
      <br />
      {defaultCard && (
        <>
          {`Expires ${longMonthNames[defaultCard.exp_month - 1]} ${
            defaultCard.exp_year
          }`}
          <br />
          <button
            className="custom-button btn-light"
            onClick={() => setRemoveOpen(true)}
          >
            Remove Payment Method
          </button>
        </>
      )}
      <button
        className="custom-button btn-dark"
        onClick={() => setAddPaymentMethodOpen(true)}
      >
        {defaultCard ? "Update" : "Add"} Payment Method
      </button>
      <br />
      <br />
      {subscription.cancel_at_period_end ? (
        <>
          {defaultCard ? (
            <>
              <b>Subscription is set to cancel at the end of the period</b>
            </>
          ) : (
            <b>Add a payment method to continue your subscription</b>
          )}
          <br />
          You have access to your subscription until{" "}
          {convertTimestamp(subscription.current_period_end, true)}
          <br />
          <br />
          {defaultCard && (
            <button
              className="custom-button btn-dark"
              onClick={() => setContinueSubscriptionOpen(true)}
            >
              Continue Subscription
            </button>
          )}
        </>
      ) : (
        <>
          <button
            className="custom-button btn-info"
            onClick={() => setCancelOpen(true)}
          >
            Cancel Subscription
          </button>
        </>
      )}
      <MiniPopup
        type="form"
        isOpen={addPaymentMethodOpen}
        onCancel={() => setAddPaymentMethodOpen(false)}
        style={{ width: 400 }}
      >
        <form onSubmit={handleGetPaymentMethod}>
          <CreditCardEntry />
          <br />
          <button className="custom-button btn-dark">Add Payment Method</button>
        </form>
      </MiniPopup>
      {confirmPopups.map((p: any) => (
        <MiniPopup
          key={p.name}
          type="confirm"
          onCancel={() => p.onCancel(false)}
          onConfirm={() => p.onConfirm()}
          isOpen={p.isOpen}
        >
          <b>Are you sure you want to {p.header}?</b>
          <br />
          <br />
          The active subscription will be set to {p.text} the end of the current
          period.
        </MiniPopup>
      ))}
    </div>
  );
};

export default CurrentSubscription;
