import React from "react";

const MainTitle = () => {
  return (
    <div>
      <img
        alt="StructureMate"
        className="loading-logo"
        src={"/logos/StructureMateInverted.png"}
      />
    </div>
  );
};

export default MainTitle;
