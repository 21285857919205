import React from "react";

import Tooltip from "./../tooltip/tooltip";

type TextAreaProps = {
  name: string;
  label: string;
  value: string;
  onChange: Function;
  tooltip?: {
    content: any;
    direction?: string;
  };
  boldHeader?: boolean;
  cols?: number;
  rows?: number;
};

const TextArea = ({
  name,
  label,
  value,
  onChange,
  tooltip,
  boldHeader,
  cols,
  rows,
}: TextAreaProps) => {
  const finalLabel = boldHeader ? <b>{label}</b> : label;
  return (
    <>
      {tooltip ? (
        <Tooltip content={tooltip.content} direction={tooltip.direction}>
          <label htmlFor={name}>{finalLabel}</label>
        </Tooltip>
      ) : (
        <div>
          <label htmlFor={name}>{finalLabel}</label>
        </div>
      )}
      <textarea
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
        cols={cols || 75}
        rows={rows || 5}
        data-testid="custom-text-area"
      />
    </>
  );
};

export default TextArea;
