import React, { useEffect } from "react";
import Icons from "../icons/icons";

type SearchBoxProps = {
  query: string;
  onSearch: (query: string) => void;
  placeholder: string;
};

const SearchBox = ({ query, onSearch, placeholder }: SearchBoxProps) => {
  useEffect(() => {
    const escFunction = (e: any) => {
      if (e.keyCode === 27) onSearch("");
    };
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [onSearch]);

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        name="query"
        placeholder={placeholder}
        value={query}
        onChange={(e) => onSearch(e.currentTarget.value)}
        className="custom-input search"
      />
      {query && (
        <div onClick={() => onSearch("")}>
          <div className="clear-button">
            <Icons iconType="cancel" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
