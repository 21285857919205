import { CardElement } from "@stripe/react-stripe-js";

export async function createPaymentMethod(stripe: any, elements: any) {
  try {
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    if (error) {
      return { error: error.message };
    }
    return { id: paymentMethod.id };
  } catch (ex: any) {
    return { error: "Could not get card data" };
  }
}
