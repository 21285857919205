import http from "./httpService";

const endpoint = "/error";
export interface IError {
  errorText: string;
  componentStack: string;
}

export async function sendError(body: IError) {
  try {
    await http.post(endpoint, body);
  } catch (ex: any) {}
}
