import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Icons from "../icons/icons";
import MiniBr from "../contentArea/miniBr";
import MiniPopup from "../contentArea/miniPopup";

type HeaderProps = {
  text: any;
  withBack?: boolean;
  backPath?: string;
  warnBack?: boolean;
  warnText?: string;
  extraButtonText?: string;
  extraButtonSymbol?: string;
  extraButtonFunction?: () => Promise<boolean> | void;
  addExtraButtonToBack?: boolean;
};

const Header = ({
  text,
  withBack,
  backPath,
  warnBack,
  warnText,
  extraButtonText,
  extraButtonSymbol,
  extraButtonFunction,
  addExtraButtonToBack,
}: HeaderProps) => {
  const navigate = useNavigate();

  const [warnOpen, setWarnOpen] = useState(false);

  const handleBack = () => {
    if (backPath) {
      if (warnBack && !warnOpen) return setWarnOpen(true);
      navigate(backPath);
    }
  };

  const renderExtraButton = (inBack: boolean) => {
    return (
      <button
        className="custom-button btn-info btn-sm"
        onClick={
          inBack
            ? async () => {
                const completed = await extraButtonFunction!();
                if (completed) handleBack();
                else setWarnOpen(false);
              }
            : extraButtonFunction
        }
      >
        {extraButtonSymbol && <Icons iconType={extraButtonSymbol} />}{" "}
        {extraButtonText}
        {inBack ? " First" : ""}
      </button>
    );
  };

  return (
    <>
      <h5 className="text-center">
        {withBack && (
          <div style={{ float: "left" }}>
            <button
              className="custom-button btn-light btn-sm"
              onClick={handleBack}
            >
              <Icons iconType="back" /> Back
            </button>
          </div>
        )}
        <b>{text}</b>
        {extraButtonFunction && (
          <div style={{ float: "right" }}>{renderExtraButton(false)}</div>
        )}
      </h5>
      {warnBack && (
        <MiniPopup
          type="confirm"
          onCancel={() => setWarnOpen(false)}
          onConfirm={handleBack}
          isOpen={warnOpen}
        >
          <b>Are you sure?</b>
          <br />
          <br />
          {warnText || ""}
          <br />
          {addExtraButtonToBack && renderExtraButton(true)}
        </MiniPopup>
      )}
      <MiniBr />
    </>
  );
};

export default Header;
