import React from "react";

import { getCurrentUser } from "../../../services/userService";

const ProfileHeader = () => {
  const user = getCurrentUser();
  if (!user) return null;
  return (
    <div className="row">
      <div className="col text-center">
        <b>{user.name}</b>
      </div>
      <div className="col text-center">
        <b>{user.email}</b>
      </div>
    </div>
  );
};

export default ProfileHeader;
