import http from "./httpService";
import { AxiosResponse } from "axios";

import { setUser } from "./userService";

const endpoint = "/auth";
export interface ILogin {
  email: string;
  password: string;
}
export interface IRegister extends ILogin {
  name: string;
  role: string;
}

interface LoginResponse {
  jwt: string;
}

export async function login(
  body: ILogin,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<LoginResponse> = await http.post(
      endpoint,
      body,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) => {
          progressFunction(http.handleProgress(progress));
        },
      }
    );
    setUser(res.data.jwt);
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function logout(navigate: Function | undefined = undefined) {
  try {
    http.deleteToken(navigate);
    return await http.delete(endpoint + "/logout");
  } catch (ex: any) {
    return ex.response;
  }
}
