import React from "react";

type TertiaryHeaderProps = {
  text: any;
  align?: string;
  leftComponent?: any;
  rightComponent?: any;
};

const TertiaryHeader = ({
  text,
  align,
  leftComponent,
  rightComponent,
}: TertiaryHeaderProps) => {
  const alignment = `text-${align || "center"}`;
  return (
    <div className="row">
      <div className={`col ${alignment}`}>{leftComponent}</div>
      <div className={`col ${alignment}`}>
        <h6>
          <b>{text}</b>
        </h6>
      </div>
      <div className={`col ${alignment}`}>{rightComponent}</div>
    </div>
  );
};

export default TertiaryHeader;
