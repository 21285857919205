import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import ProtectedRoute from "./protectedRoute";
import Register from "../user/register";
import Login from "./../user/login";
import Profile from "../user/profile/profile";
import Help from "../help/help";
import ProjectsRedirect from "../projects/projectsRedirect";

interface IRoute {
  path: string;
  element: any;
  protected: boolean;
}

const SwitchRouter = () => {
  const routes: IRoute[] = [
    // { path: "*", element: PageNotFound, protected: false },
    { path: "login", element: Login, protected: false },
    { path: "register", element: Register, protected: false },
    { path: "profile", element: Profile, protected: true },
    { path: "projects", element: ProjectsRedirect, protected: true },
    { path: "help", element: Help, protected: false },
  ];

  return (
    <Routes>
      {routes.map((r) => (
        <Route
          key={r.path}
          path={`/${r.path}`}
          element={
            r.protected ? (
              <ProtectedRoute component={<r.element />} route={r.path} />
            ) : (
              <r.element />
            )
          }
        />
      ))}
      <Route path="*" element={<Navigate replace to="/help" />} />
    </Routes>
  );
};

export default SwitchRouter;
