import React from "react";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-modal";

import MainTitle from "./mainTitle";
import { popupStyle } from "./../../../utils/styles";

type LoadingProps = {
  loading: boolean;
  progress?: number;
};

const Loading = ({ loading, progress }: LoadingProps) => {
  return loading ? (
    <Modal isOpen={loading} ariaHideApp={false} style={popupStyle}>
      <div className="text-center">
        <MainTitle />
        <br />
        <Spinner animation="grow" className="spinner" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <br />
        <br />
        {progress && progress > 0 ? (
          <ProgressBar variant="info" now={progress} />
        ) : null}
      </div>
    </Modal>
  ) : null;
};

export default Loading;
