import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";
import "./css/index.css";
import "./css/buttons.css";
import "./css/tooltip.css";
import "./css/tab.css";
import "./css/inputs.css";
import "./css/icon.css";
import "./css/nav.css";
import "./css/table.css";
import "./css/pageSection.css";
import "./css/text.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
