import http from "./httpService";
import { IProject } from "../types/projectTypes";
import { AxiosResponse } from "axios";

const endpoint = "/projects";

export async function getProject(
  projectID: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.get(
      endpoint + "/" + projectID,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function getProjects(progressFunction: (progress: number) => {}) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.get(
      endpoint,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function saveProject(
  projectID: string,
  body: IProject,
  progressFunction: (progress: number) => {}
) {
  try {
    if (projectID === "new")
      return await http.post(
        endpoint,
        body,
        progressFunction && {
          onUploadProgress: (progress: ProgressEvent) =>
            progressFunction(http.handleProgress(progress)),
        }
      );
    else
      return await http.put(
        endpoint + "/" + projectID,
        body,
        progressFunction && {
          onUploadProgress: (progress: ProgressEvent) =>
            progressFunction(http.handleProgress(progress)),
        }
      );
  } catch (ex: any) {
    return ex.response;
  }
}

export async function deleteProject(
  projectID: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.delete(
      endpoint + "/" + projectID,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function permanentlyDeleteProject(
  projectID: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.delete(
      endpoint + "/permanent/" + projectID,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

// to make an existing project not deleted
export async function activateProject(
  projectID: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.put(
      endpoint + "/activate/" + projectID,
      progressFunction && {
        onUploadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}
