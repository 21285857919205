import React from "react";
import { IData, IInput, ITableColumns } from "../../../types/generics";
import TableHeader from "../table/tableHeader";
import Input from "./input";

import Icons from "../icons/icons";

type InputTableProps = {
  data: IData[];
  inputs: IInput[];
  onChange: (property: string, value: string, index: number) => void;
  onReorder?: (index: number, direction: string) => void;
  length?: number;
  onDelete?: (index: number) => void;
};

const InputTable = ({
  data,
  inputs,
  onChange,
  onReorder,
  length,
  onDelete,
}: InputTableProps) => {
  let columns: ITableColumns[] = inputs.map((i) => {
    return { path: i.name, label: i.label };
  });
  if (onDelete) columns.unshift({ path: "delete", label: "" });
  if (onReorder) columns.push({ path: "reorder", label: "" });

  return (
    <table className="custom-table">
      <TableHeader columns={columns} />
      <tbody>
        {data.map((d, i) => (
          <tr className="table-row" key={i}>
            {onReorder && length ? (
              <td>
                <div>
                  {i > 0 ? (
                    <div
                      className="clickable"
                      onClick={() => onReorder(i, "up")}
                      style={{ display: "block" }}
                    >
                      <Icons iconType="up" />
                    </div>
                  ) : (
                    <div style={{ height: 15 }} />
                  )}
                  {i < length - 1 && (
                    <div
                      className="clickable"
                      onClick={() => onReorder(i, "up")}
                      style={{ display: "block" }}
                    >
                      <Icons iconType="down" />
                    </div>
                  )}
                </div>
              </td>
            ) : null}
            {inputs.map((input, ii) => (
              <td className="table-cell" key={ii}>
                <Input
                  name={input.name}
                  label={input.label}
                  hideLabel={true}
                  value={data[i][input.name]}
                  type={input.type}
                  onChange={(value) => onChange(input.name, value, i)}
                  tooltip={input.tooltip}
                  usePasswordTooltip={input.usePasswordTooltip}
                  style={input.setWidth ? { width: input.setWidth } : {}}
                  disabled={input.disabled}
                  autofocus={input.autofocus}
                  table={true}
                />
              </td>
            ))}

            {onDelete && (
              <td>
                <div className="custom-input-map-buttons-button">
                  <button
                    className="custom-button btn-sm btn-light"
                    onClick={() => onDelete(i)}
                  >
                    <Icons iconType="delete" className="icon-raised" />
                  </button>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InputTable;
