import React from "react";

import { titleCase } from "../../../utils/allowables";
import Icons from "../icons/icons";

type HorizontalTabsProps = {
  tabs: Array<string>;
  icons?: string[];
  selectedTab: string;
  onSelectTab: (tab: string) => void;
};

const HorizontalTabs = ({
  tabs,
  selectedTab,
  onSelectTab,
  icons,
}: HorizontalTabsProps) => {
  return (
    <div className="row">
      {tabs.map((t, idx) => {
        const icon = icons && icons[idx] ? icons[idx] : "";
        const isSelected = selectedTab === t;
        return (
          <div className="col" key={idx}>
            <div
              className={"custom-tab h-tab" + (isSelected ? " selected" : "")}
              onClick={() => onSelectTab(t)}
            >
              {
                <Icons
                  iconType={icon}
                  className={"icon-tabs" + (isSelected ? "-selected" : "")}
                />
              }
              {icon ? " " : ""}
              {titleCase(t)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalTabs;
