import http from "./httpService";
import { AxiosResponse } from "axios";

const endpoint = "/tables";

export async function getTable(
  version: string,
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.get(
      endpoint + "/" + version,
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}

export async function getLateralSystems(
  progressFunction: (progress: number) => {}
) {
  try {
    const res: AxiosResponse<{ data: string }> = await http.get(
      endpoint + "/lateralsystems/all",
      progressFunction && {
        onDownloadProgress: (progress: ProgressEvent) =>
          progressFunction(http.handleProgress(progress)),
      }
    );
    return res;
  } catch (ex: any) {
    return ex.response;
  }
}
