import React, { useState } from "react";
import { IDeadLoad, IWeightObject } from "../../../types/projectTypes";
import Input from "../../common/form/input";
import { blankWeightObject } from "../../../utils/projectsUtil";
import InputTable from "../../common/form/inputTable";
import MiniPopup from "../../common/contentArea/miniPopup";
import Icons from "../../common/icons/icons";
import VertSpace from "../../common/contentArea/vertSpace";
import Select from "../../common/form/select";
import MiniBr from "../../common/contentArea/miniBr";
import { IData } from "../../../types/generics";
import TertiaryHeader from "../../common/text/tertiaryHeader";
import Units from "../../common/text/units";

type DeadLoadProps = {
  deadLoad: IDeadLoad;
  onUpdateDeadLoadInfo: (property: string, value: string) => void;
  onUpdateDeadLoadArray: (
    property: string,
    value: IWeightObject[],
    skipCacl: boolean
  ) => void;
  onCopyDeadLoad: (deadLoad: IDeadLoad) => void;
  onDeleteDeadLoad: () => void;
  units: string;
  levels: IData[];
};

const DeadLoad = ({
  deadLoad,
  onUpdateDeadLoadInfo,
  onUpdateDeadLoadArray,
  onCopyDeadLoad,
  onDeleteDeadLoad,
  units,
  levels,
}: DeadLoadProps) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [copyOpen, setCopyOpen] = useState(false);

  const addToDeadLoadArray = (property: string) => {
    let updatedDeadLoad = { ...deadLoad[property] };
    updatedDeadLoad.deadLoads.push(blankWeightObject);
    onUpdateDeadLoadArray(property, updatedDeadLoad, true);
  };

  const updateDeadLoadArray = (
    arrayProperty: string,
    property: string,
    value: string,
    index: number
  ) => {
    let updatedDeadLoad = { ...deadLoad[arrayProperty] };
    let item = { ...updatedDeadLoad.deadLoads[index] };
    item[property] = value;
    updatedDeadLoad.deadLoads.splice(index, 1, item);
    onUpdateDeadLoadArray(
      arrayProperty,
      updatedDeadLoad,
      property !== "weight"
    );
  };

  const handleReorderDeadLoadArray = (
    arrayProperty: string,
    index: number,
    direction: string
  ) => {
    let updatedDeadLoad = { ...deadLoad[arrayProperty] };
    let item = { ...updatedDeadLoad.deadLoads[index] };
    updatedDeadLoad.deadLoads.splice(index, 1);
    updatedDeadLoad.deadLoads.splice(
      index + (direction === "up" ? -1 : 1),
      0,
      item
    );
    onUpdateDeadLoadArray(arrayProperty, updatedDeadLoad, true);
  };

  const handleDeleteDeadLoadArrayItem = (
    arrayProperty: string,
    index: number
  ) => {
    let updatedDeadLoad = { ...deadLoad[arrayProperty] };
    updatedDeadLoad.deadLoads.splice(index, 1);
    onUpdateDeadLoadArray(arrayProperty, updatedDeadLoad, false);
  };

  const weightInputs = [
    { name: "name", label: "Name", type: "text", autofocus: true },
    {
      name: "weight",
      label: <Units text={"Weight "} units={units} type="pressure" />,
      type: "number",
    },
  ];
  const renderDeadLoadType = (typeIndex: number) => {
    const deadLoadType = deadLoadTypes[typeIndex];
    const thisDeadLoad = deadLoad[deadLoadType.name];
    if (!thisDeadLoad) return null;
    return (
      <>
        <b>{deadLoadType.label}s</b>:{" "}
        <Units
          text={thisDeadLoad.totalWeight}
          units={units}
          type="pressure"
          noParentheses={true}
        />
        {thisDeadLoad.deadLoads.length > 0 && (
          <InputTable
            data={thisDeadLoad.deadLoads}
            inputs={weightInputs}
            onChange={(property: string, value: string, index: number) =>
              updateDeadLoadArray(deadLoadType.name, property, value, index)
            }
            onReorder={(index: number, direction: string) =>
              handleReorderDeadLoadArray(deadLoadType.name, index, direction)
            }
            length={thisDeadLoad.deadLoads.length}
            onDelete={(index: number) =>
              handleDeleteDeadLoadArrayItem(deadLoadType.name, index)
            }
          />
        )}
        <br />
        <button
          className="custom-button btn-dark"
          onClick={() => addToDeadLoadArray(deadLoadType.name)}
        >
          <Icons iconType="add" /> Add New {deadLoadType.label}
        </button>
      </>
    );
  };

  const deadLoadTypes = [
    { name: "superimposed", label: "Superimposed Dead Load" },
    { name: "selfWeight", label: "Self Weight" },
    { name: "additional", label: "Additional Seismic Dead Load" },
    { name: "misc", label: "Miscellaneous Dead Load" },
  ];

  return (
    <div className="list-item text-center">
      <div className="row">
        <TertiaryHeader text={deadLoad.name || "New Dead Load"} />
        <div className="text-center">
          <Units
            text={deadLoad.totalWeight}
            units={units}
            type="pressure"
            noParentheses={true}
          />
        </div>
        <div className="row">
          <div className="col">
            <Input
              name="name"
              label="Name"
              // hideLabel={true}
              value={deadLoad.name}
              type="text"
              onChange={(value) => onUpdateDeadLoadInfo("name", value)}
              style={{ width: "50%" }}
              placeholder="Dead Load Name"
              boldHeader={true}
            />
          </div>
          <div className="col-3 text-center">
            <Select
              name="direction"
              label="Direction"
              options={[
                { id: "horizontal", label: "Horizontal" },
                { id: "vertical", label: "Vertical" },
              ]}
              selectedOption={deadLoad.direction}
              onChange={(value: string) =>
                onUpdateDeadLoadInfo("direction", value)
              }
              style={{ width: "100%" }}
              boldHeader={true}
            />
          </div>
          <div className="col-3 text-center">
            <Select
              name="levelName"
              label="Level"
              options={levels.map((l) => {
                return { id: l.name, label: l.name };
              })}
              selectedOption={deadLoad.levelName}
              onChange={(value: string) =>
                onUpdateDeadLoadInfo("levelName", value)
              }
              style={{ width: "100%" }}
              boldHeader={true}
            />
          </div>
        </div>
        <MiniBr />
        <div className="col text-center">{renderDeadLoadType(0)}</div>
        <div className="col text-center">
          {renderDeadLoadType(1)}
          <br />
          <br />
          {renderDeadLoadType(2)}
          <br />
          <br />
          {renderDeadLoadType(3)}
        </div>
      </div>
      <button
        className="custom-button btn-info"
        onClick={() => setCopyOpen(true)}
      >
        <Icons iconType="copy" /> Copy Dead Load
      </button>
      <VertSpace spaces={10} />
      <button
        className="custom-button btn-light"
        onClick={() => setDeleteOpen(true)}
      >
        <Icons iconType="delete" /> Delete Dead Load
      </button>
      <MiniPopup
        type="confirm"
        onCancel={() => setDeleteOpen(false)}
        onConfirm={onDeleteDeadLoad}
        isOpen={deleteOpen}
      >
        <b>Delete this dead load?</b>
      </MiniPopup>
      <MiniPopup
        type="confirm"
        onCancel={() => setCopyOpen(false)}
        onConfirm={() => onCopyDeadLoad(deadLoad)}
        isOpen={copyOpen}
      >
        <b>Copy this dead load?</b>
      </MiniPopup>
    </div>
  );
};

export default DeadLoad;
