import React from "react";

type SubscriptProps = {
  preText?: string;
  subText: any;
  postText?: string;
  symbolAsSubText?: any;
};

const Subscript = ({ preText, subText, postText }: SubscriptProps) => {
  return (
    <>
      {preText}
      <sub>{subText}</sub>
      {postText}
    </>
  );
};

export default Subscript;
