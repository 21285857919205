import React, { useState } from "react";
import { IProject } from "../../types/projectTypes";
import MiniPopup from "../common/contentArea/miniPopup";
import { convertDate, titleCase } from "../../utils/allowables";
import Icons from "../common/icons/icons";

type ProjectProps = {
  project: IProject;
  onSelectProject: (project: IProject) => void;
  onPermaDeleteProject: (project: IProject) => void;
};

const Project = ({
  project,
  onSelectProject,
  onPermaDeleteProject,
}: ProjectProps) => {
  const [permaDeleteOpen, setPermaDeleteOpen] = useState(false);
  const { info } = project;
  const renderItem = (label: string, text: string) => {
    return (
      <div className="list-item-text">
        <b>{label}: </b>
        {text}
        <br />
      </div>
    );
  };

  return (
    <div className="row list-item">
      <div className="col">
        {renderItem("Name", info.name)}
        {renderItem("Address", info.address)}
        {renderItem("Job ID", info.jobID)}
        {renderItem("Date", info.date)}
        {renderItem("Units", titleCase(info.units))}
        {project.deleted
          ? renderItem("Deleted Date", convertDate(project.deleted))
          : null}
      </div>
      <div className="col-3">
        <button
          className="custom-button btn-sm btn-info"
          onClick={() => onSelectProject(project)}
        >
          <Icons iconType="open" /> {project.deleted ? "Activate" : "Open"}
        </button>
        {project.deleted && (
          <>
            <br />
            <button
              className="custom-button btn-sm btn-danger"
              onClick={() => setPermaDeleteOpen(true)}
            >
              <Icons iconType="delete" /> Delete Permanently
            </button>
            <MiniPopup
              type="confirm"
              onCancel={() => setPermaDeleteOpen(false)}
              onConfirm={() => onPermaDeleteProject(project)}
              isOpen={permaDeleteOpen}
            >
              <b>The project "{info.name}" will be permanently deleted</b>
              <br />
              <br />
              Are you sure?
            </MiniPopup>
          </>
        )}
      </div>
    </div>
  );
};

export default Project;
