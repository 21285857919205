import React from "react";

type WidthTooSmallProps = {
  width?: number;
};

const WidthTooSmall = ({ width = 500 }: WidthTooSmallProps) => {
  return (
    <div className="custom-alert text-center" style={{ fontSize: 12 }}>
      Your screen is less than {width} pixels wide. You can still use the app
      but some elements may not display correctly.
    </div>
  );
};

export default WidthTooSmall;
