import React, { useState, useContext, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TabbedArea from "../../common/contentArea/tabbedArea";
import Header from "../../common/text/header";
import InfoTab from "./infoTab";
import {
  blankProjectInfo,
  blankLevel,
  blankDeadLoad,
  blankSeismicData,
  deadLoadTypes,
  calculateHorizontalMass,
  calculateVerticalMass,
} from "../../../utils/projectsUtil";
import { calculateSeismicData } from "../../../utils/seismicUtil";
import { IData } from "../../../types/generics";
import {
  getProject,
  saveProject,
  deleteProject,
} from "../../../services/projectService";
import { getTable, getLateralSystems } from "../../../services/tablesService";
import LoadingContext from "../../../context/loadingContext";
import Icons from "../../common/icons/icons";
import {
  IProjectInfo,
  ILevel,
  IProject,
  IWeightObject,
  IDeadLoad,
} from "../../../types/projectTypes";
import MiniPopup from "../../common/contentArea/miniPopup";
import UserContext from "../../../context/userContext";
import DeadLoads from "./deadLoads";
import MassTab from "./massTab";
import SeismicTab from "./seismicTab";
type NewProjectTakeoffProps = {
  projectID: string;
};

const NewProjectTakeoff = ({ projectID }: NewProjectTakeoffProps) => {
  const navigate = useNavigate();
  const { setLoading, setProgress } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const newBlankProjectInfo = {
    ...blankProjectInfo,
    units: user?.settings?.units || "imperial",
  };
  const tabs = ["info", "dead load", "mass", "seismic", "elf"];
  const icons = ["info", "download", "weight", "earthquake", "arrows"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  // const [autoSave, setAutoSave] = useState(0);
  // const [autoSaveInterval, setAutoSaveInterval] = useState<NodeJS.Timer | null>(
  //   null
  // );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [baseProject, setBaseProject] = useState<IData>({
    info: newBlankProjectInfo,
    levels: [],
    deadLoads: [],
    seismicInfo: blankSeismicData,
  });
  const [projectInfo, setProjectInfo] = useState<IData>(newBlankProjectInfo);
  const [levels, setLevels] = useState<IData[]>([]);
  const [deadLoads, setDeadLoads] = useState<IData[]>([]);
  const [deadLoadTotalWeight, setDeadLoadTotalWeight] = useState(0);
  const [seismicInfo, setSeismicInfo] = useState<IData>(blankSeismicData);
  const [table, setTable] = useState(null);
  const [lateralSystems, setLateralSystems] = useState([]);

  const handlePullTables = useCallback(
    async (version: string) => {
      setLoading(true);
      setProgress(1);
      const res = await getTable(version, async (p) => setProgress(p));
      if (res.status === 200) {
        setTable(res.data);
        setLoading(false);
        return res.data;
      } else toast.error(res.data);

      setLoading(false);
    },
    [setLoading, setProgress]
  );

  const loadProject = useCallback(async () => {
    setLoading(true);
    setProgress(1);
    let table = projectInfo.table;
    let seismicInfo = { ...blankSeismicData };
    if (projectID !== "new") {
      const res = await getProject(projectID, async (p) => setProgress(p));
      if (res.status === 200) {
        const lateralSystemsRes = await getLateralSystems(async (p) =>
          setProgress(p)
        );
        if (lateralSystemsRes.status === 200)
          setLateralSystems(lateralSystemsRes.data);
        else toast.error(lateralSystemsRes.data);
        setBaseProject({
          info: res.data.info,
          levels: res.data.levelID?.levels || [],
          deadLoads: res.data.deadLoadID?.deadLoads || [],
          deadLoadTotalWeight: res.data.deadLoadID?.totalWeight || 0,
          seismicInfo: res.data.seismicInfo || blankSeismicData,
        });
        setProjectInfo(res.data.info);
        setLevels(res.data.levelID?.levels || []);
        setDeadLoads(res.data.deadLoadID?.deadLoads || []);
        setDeadLoadTotalWeight(res.data.deadLoadID?.totalWeight || 0);
        setSeismicInfo(res.data.seismicID?.seismicInfo || blankSeismicData);
      } else toast.error(res.data);
      table = res.data.info?.table || table;
      seismicInfo = res.data.seismicID?.seismicInfo || seismicInfo;
    }
    const book = await handlePullTables(table);
    handleCalculateSeismicInfo(seismicInfo, false, book);
    setLoading(false);
  }, [
    setLoading,
    setProgress,
    setBaseProject,
    setProjectInfo,
    setLevels,
    projectID,
    handlePullTables,
  ]);

  useEffect(() => {
    loadProject();
  }, [loadProject]);

  const handleSaveProject = async () => {
    setLoading(true);
    setProgress(1);
    let completed = false;
    const { newLevels, newDeadLoads } = handleCalculateAllLevelMass(
      levels,
      deadLoads
    );
    const newSeismicInfo = handleCalculateSeismicInfo(seismicInfo, false);

    let project: IProject = {
      info: projectInfo as IProjectInfo,
      levels: newLevels as ILevel[],
      deadLoads: newDeadLoads as IDeadLoad[],
      deadLoadTotalWeight,
      seismicInfo: newSeismicInfo,
    };
    const res = await saveProject(projectID, project, async (p) =>
      setProgress(p)
    );

    if (res.status === 200) {
      navigate(`/projects?id=${res.data}`);
      setBaseProject({
        info: projectInfo,
        levels,
        deadLoads,
      });
      toast.success("Project Saved");
      completed = true;
    } else toast.error(res.data);

    setLoading(false);
    return completed;
  }; // for autosaving
  // ! This is not currently working
  // ! handleSaveProject does not receive updated values
  // ! when called inside the useInterval
  // useEffect(() => {
  //   if (autoSaveInterval) clearInterval(autoSaveInterval);
  //   if (autoSave > 0) {
  //     const interval = setInterval(async () => {
  //       await handleSaveProject();
  //     }, autoSave * 1000 * 10);
  //     setAutoSaveInterval(interval);
  //   }
  //   return () => {
  //     if (autoSaveInterval) clearInterval(autoSaveInterval);
  //   };
  // }, [autoSave]);

  const handleUpdateProjectInfo = (property: string, value: string) => {
    let updatedProject = { ...projectInfo };
    updatedProject[property] = value;
    if (property === "table") handlePullTables(value);
    setProjectInfo(updatedProject);
  };

  const handleAddLevel = (index: number) => {
    if (index === -1) return setLevels([]);
    let updatedLevels = [...levels];
    updatedLevels.splice(index, 0, blankLevel);
    setLevels(updatedLevels);
  };

  const handleCalculateLevelElevation = (
    updatedLevels: IData[],
    skipCalc?: boolean
  ) => {
    if (!skipCalc) {
      let totalElevation = 0;

      for (let i = updatedLevels.length - 1; i >= 0; i--) {
        totalElevation += Number(updatedLevels[i].height || 0);
        updatedLevels[i].elevation = totalElevation;
      }
    }

    handleCalculateAllLevelMass(updatedLevels, deadLoads);
  };

  const handleUpdateLevels = (
    property: string,
    value: string,
    index: number
  ) => {
    let updatedLevels = [...levels];
    let updatedLevel = { ...levels[index] };
    updatedLevel[property] = value;
    updatedLevels.splice(index, 1, updatedLevel);
    handleCalculateLevelElevation(updatedLevels, property !== "height");
  };

  const handleReorderLevels = (index: number, direction: string) => {
    let updatedLevels = [...levels];
    let currentLevel = { ...updatedLevels[index] };
    updatedLevels.splice(index, 1);
    updatedLevels.splice(
      index + (direction === "up" ? -1 : 1),
      0,
      currentLevel
    );
    handleCalculateLevelElevation(updatedLevels);
  };

  const handleDeleteLevel = (index: number) => {
    let updatedLevels = [...levels];
    updatedLevels.splice(index, 1);
    handleCalculateLevelElevation(updatedLevels);
  };

  const handleAddMultipleLevels = (copy: IData) => {
    let levelsToAdd = [];

    for (let i = 1; i <= Number(copy.number); i++) {
      levelsToAdd.push({
        height: Number(copy.height),
        length: Number(copy.length),
        width: Number(copy.width),
        name: `${copy.baseName}${i}`,
        group: copy.baseName,
      });
    }

    handleCalculateLevelElevation([...levels, ...levelsToAdd]);
  };

  const handleAddDeadLoad = (deadLoad: IDeadLoad = blankDeadLoad) => {
    let newDeadLoads = [...deadLoads];
    let newDeadLoad: IData = {};

    for (let prop in deadLoad) {
      if (deadLoadTypes.includes(prop)) {
        let newType: IData = {};
        newType.deadLoads = [...deadLoad[prop].deadLoads];
        newType.totalWeight = deadLoad[prop].totalWeight;
        newDeadLoad[prop] = newType;
      } else newDeadLoad[prop] = deadLoad[prop];
    }

    newDeadLoads.push(newDeadLoad);
    setDeadLoadTotalWeight(deadLoadTotalWeight + newDeadLoad.totalWeight);
    handleCalculateAllLevelMass(levels, newDeadLoads);
  };

  const handleDeleteDeadLoad = (index: number) => {
    const weightRemoved = deadLoads[index].totalWeight;
    let newDeadLoads = [...deadLoads];
    newDeadLoads.splice(index, 1);
    setDeadLoadTotalWeight(deadLoadTotalWeight - weightRemoved);
    handleCalculateAllLevelMass(levels, newDeadLoads);
  };

  const handleUpdateDeadLoadInfo = (
    property: string,
    value: string,
    index: number
  ) => {
    let newDeadLoads = [...deadLoads];
    let newDeadLoad = { ...newDeadLoads[index] };
    newDeadLoad[property] = value;
    newDeadLoads.splice(index, 1, newDeadLoad);
    if (property !== "name") handleCalculateAllLevelMass(levels, newDeadLoads);
    else setDeadLoads(newDeadLoads);
  };

  const handleCalculateAllLevelMass = (
    updatedLevels: IData[],
    updatedDeadLoads: IData[]
  ) => {
    console.log(updatedLevels);
    let newLevels: IData[] = [];
    let newDeadLoads: IData[] = [];
    updatedDeadLoads.forEach((a) => {
      let assembly = { ...a };
      let deadLoadLevelIndex = updatedLevels.findIndex(
        (l) => l.name === a.levelName
      );
      let deadLoadLevel = updatedLevels[deadLoadLevelIndex];
      let height = updatedLevels[deadLoadLevelIndex - 1]
        ? (Number(updatedLevels[deadLoadLevelIndex - 1].height) +
            Number(deadLoadLevel.height)) /
          2
        : deadLoadLevel.height / 2;

      if (
        assembly.direction === "horizontal" &&
        assembly.levelName === deadLoadLevel?.name
      ) {
        const horizontalMass = calculateHorizontalMass(
          assembly.area,
          assembly.totalWeight
        );
        assembly.horizontalMass = horizontalMass;
        assembly.verticalMass = 0;
      } else if (
        assembly.direction === "vertical" &&
        assembly.levelName === deadLoadLevel?.name
      ) {
        const verticalMass = calculateVerticalMass(
          assembly.area,
          assembly.totalWeight,
          height
        );
        assembly.verticalMass = verticalMass;
        assembly.horizontalMass = 0;
      }

      newDeadLoads.push(assembly);
    });
    updatedLevels.forEach((l) => {
      let level = { ...l };
      let assemblies = newDeadLoads.filter((a) => a.levelName === level.name);
      level.horizontalMass = 0;
      level.verticalMass = 0;
      assemblies.forEach((a) => {
        level.horizontalMass += a.horizontalMass;
        level.verticalMass += a.verticalMass;
      });
      newLevels.push(level);
    });
    setLevels(newLevels);
    setDeadLoads(newDeadLoads);
    return {
      newLevels,
      newDeadLoads,
    };
  };

  const handleUpdateDeadLoadArray = (
    property: string,
    value: IWeightObject[],
    index: number,
    skipCalc?: boolean
  ) => {
    let newDeadLoads = [...deadLoads];
    let newDeadLoad = newDeadLoads[index];
    newDeadLoad[property] = value;
    newDeadLoads.splice(index, 1, newDeadLoad);
    if (skipCalc) setDeadLoads(newDeadLoads);
    else handleCalculateWholeDeadLoad(newDeadLoads, index, property);
  };

  const handleCalculateWholeDeadLoad = (
    updatedDeadLoads: IData[],
    updatedIndex: number,
    arrayProperty: string,
    skipCalc?: boolean
  ) => {
    const recalcLoad = { ...updatedDeadLoads[updatedIndex] };
    const recalcType = { ...recalcLoad[arrayProperty] };

    if (!skipCalc) {
      let newArrayWeight = 0;
      recalcType.deadLoads.forEach((l: any) => {
        newArrayWeight += Number(l.weight);
      });
      recalcType.totalWeight = newArrayWeight;
      let totalDeadLoadWeight = newArrayWeight;

      for (let type in recalcLoad) {
        if (![arrayProperty, "name", "totalWeight"].includes(type)) {
          totalDeadLoadWeight += Number(recalcLoad[type].totalWeight || 0);
        }
      }

      recalcLoad.totalWeight = totalDeadLoadWeight;
      recalcLoad[arrayProperty] = recalcType;
      let totalStructureWeight = totalDeadLoadWeight;

      for (let i = 0; i < deadLoads.length; i++) {
        if (i !== updatedIndex) {
          totalStructureWeight += deadLoads[i].totalWeight;
        }
      }

      setDeadLoadTotalWeight(totalStructureWeight);
    }

    updatedDeadLoads.splice(updatedIndex, 1, recalcLoad);
    handleCalculateAllLevelMass(levels, updatedDeadLoads);
  };

  const handleUpdateSeismicInfo = (property: string, value: string) => {
    let updatedSeismicInfo = { ...seismicInfo };
    updatedSeismicInfo[property] = value;
    const skippableProperties = ["soilsReport"];
    handleCalculateSeismicInfo(
      updatedSeismicInfo,
      skippableProperties.includes(property)
    );
  };

  const handleCalculateSeismicInfo = (
    updatedSeismicInfo: IData,
    skipCalc: boolean,
    book?: any
  ) => {
    if (!skipCalc) {
      updatedSeismicInfo = calculateSeismicData(
        updatedSeismicInfo,
        levels.length > 0 ? levels[0].elevation : 0,
        book || table,
        lateralSystems
      );
    }
    setSeismicInfo(updatedSeismicInfo);
    return updatedSeismicInfo;
  };

  const handleDeleteProject = async () => {
    setLoading(true);
    setProgress(1);
    const res = await deleteProject(projectID, async (p) => setProgress(p));

    if (res.status === 200) {
      toast.success("Project Marked for Deletion");
      return navigate("/projects");
    } else toast.error(res.data);

    setLoading(false);
  };

  const handleResetChanges = () => {
    setProjectInfo(baseProject.info);
    setLevels(baseProject.levels);
    setDeadLoads(baseProject.deadLoads);
    setDeadLoadTotalWeight(baseProject.deadLoadTotalWeight);
  };

  return (
    <>
      <Header
        text={projectInfo.name || "New Project"}
        withBack={true}
        backPath="/projects"
        warnBack={true}
        warnText="All unsaved changes will be lost."
        extraButtonFunction={handleSaveProject}
        extraButtonText="Save Project"
        extraButtonSymbol="save"
        addExtraButtonToBack={true}
      />
      <TabbedArea
        tabPlacement="top"
        tabs={tabs}
        icons={icons}
        onSelectTab={setSelectedTab}
        selectedTab={selectedTab}
      >
        {selectedTab === "info" ? (
          <InfoTab
            projectInfo={projectInfo}
            onUpdateProjectInfo={handleUpdateProjectInfo}
            onAddLevel={handleAddLevel}
            onUpdateLevels={handleUpdateLevels}
            levels={levels}
            onReorderLevels={handleReorderLevels}
            onDeleteLevel={handleDeleteLevel}
            onAddMultipleLevels={handleAddMultipleLevels}
          />
        ) : selectedTab === "dead load" ? (
          <DeadLoads
            deadLoads={deadLoads}
            onAddDeadLoad={handleAddDeadLoad}
            onUpdateDeadLoadInfo={handleUpdateDeadLoadInfo}
            onUpdateDeadLoadArray={handleUpdateDeadLoadArray}
            onDeleteDeadLoad={handleDeleteDeadLoad}
            units={projectInfo.units}
            deadLoadTotalWeight={deadLoadTotalWeight}
            levels={levels}
          />
        ) : selectedTab === "mass" ? (
          <MassTab
            levels={levels}
            deadLoads={deadLoads}
            onUpdateDeadLoad={handleUpdateDeadLoadInfo}
            units={projectInfo.units}
          />
        ) : selectedTab === "seismic" ? (
          <SeismicTab
            seismicInfo={seismicInfo}
            onUpdateSeismicInfo={handleUpdateSeismicInfo}
            maxElevation={levels.length > 0 ? levels[0].elevation : 0}
            lateralSystems={lateralSystems}
          />
        ) : (
          <></>
        )}
      </TabbedArea>
      <div>
        <div
          style={{
            float: "left",
          }}
        >
          {projectID !== "new" && (
            <button
              className="custom-button btn-danger"
              onClick={() => setDeleteOpen(true)}
              tabIndex={-1}
            >
              <Icons iconType="markDelete" /> Mark for Deletion
            </button>
          )}
        </div>
        <div
          style={{
            float: "right",
          }}
        >
          {projectID !== "new" && (
            <button
              className="custom-button btn-light"
              onClick={() => setResetOpen(true)}
              tabIndex={-1}
            >
              <Icons iconType="undo" /> Revert Unsaved Changes
            </button>
          )}
        </div>
      </div>
      <MiniPopup
        type="confirm"
        onCancel={() => setDeleteOpen(false)}
        onConfirm={handleDeleteProject}
        isOpen={deleteOpen}
      >
        <b>Mark this project for deletion?</b>
        <br />
        The project will remain accessible to reactivate for a set period of
        time or until you permanently delete it.
      </MiniPopup>
      <MiniPopup
        type="confirm"
        onCancel={() => setResetOpen(false)}
        onConfirm={handleResetChanges}
        isOpen={resetOpen}
      >
        <b>Revert all unsaved changes to this project?</b>
        <br />
        This cannot be undone
      </MiniPopup>
    </>
  );
};

export default NewProjectTakeoff;
