import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Input from "./../common/form/input";
import {
  getCurrentUser,
  requestPasswordReset,
  updatePassword,
} from "../../services/userService";
import { IInput, IData } from "../../types/generics";
import { login, ILogin } from "../../services/authService";
import Header from "./../common/text/header";
import LoadingContext from "../../context/loadingContext";
import UserContext from "./../../context/userContext";
import MiniPopup from "../common/contentArea/miniPopup";
import InputMap from "../common/form/inputMap";

const Login = () => {
  const { setLoading, setProgress } = useContext(LoadingContext);
  const { setUser } = useContext(UserContext);
  let location = useLocation();
  let navigate = useNavigate();
  const [data, setData] = useState<IData>({
    email: "",
    password: "",
  });
  const [resetOpen, setResetOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetToken, setResetToken] = useState("");

  useEffect(() => {
    const loadParams = () => {
      const search = location.search;
      if (search) {
        const split = search.split("&");
        const email = split.find((s) => s.includes("email"));
        let newData = { ...data };
        newData.email = email ? email.split("=")[1] || "" : "";
        setData(newData);
        const token = split.find((s) => s.includes("token"));
        setResetToken(token ? token.split("=")[1] || "" : "");
      }
    };
    loadParams();
  }, [data, location.search]);

  const handleChange = (property: string, value: string) => {
    let newData = { ...data };
    newData[property] = value;
    setData(newData);
  };

  const inputs: IInput[] = [
    { name: "email", label: "Email", type: "text", tooltip: null },
    {
      name: "password",
      label: "Password",
      type: "password",
      usePasswordTooltip: true,
    },
  ];

  const handlePasswordResetRequest = async () => {
    setLoading(true);
    setProgress(1);
    const res = await requestPasswordReset(resetEmail, async (p) =>
      setProgress(p)
    );
    if (res.status === 200) toast.success(res.data);
    else toast.error(res.data);
    setLoading(false);
  };

  const handleResetPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    const res = await updatePassword(
      data.email,
      data.password,
      resetToken,
      async (p) => setProgress(p)
    );
    if (res.status === 200) {
      navigate("/login");
      setData({ email: data.email, password: "" });
      setResetToken("");
      toast.success(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setProgress(1);
    const res = await login(data as ILogin, async (p) => setProgress(p));
    if (res.status === 200) {
      let user = getCurrentUser();
      setUser(user);
      navigate(`/${location.hash.slice(1) || "profile"}`);
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div className="text-center">
      <b>You can only be loggeed in on a single browser at a time</b>
      <br />
      If you are logged in on another browser please make sure that your work is
      saved there before logging in here
      <hr />
      <Header text={resetToken ? "Enter New Password" : "Login"} />
      <form onSubmit={resetToken ? handleResetPassword : handleSubmit}>
        <InputMap inputs={inputs} onChange={handleChange} data={data} />
        <br />
        <button className="custom-button btn-dark submit-button">
          {resetToken ? "Update Password" : "Login"}
        </button>
      </form>
      {!resetToken && (
        <button
          className="custom-button btn-info submit-button"
          onClick={() => setResetOpen(true)}
        >
          Forgot Password?
        </button>
      )}
      <MiniPopup
        type="confirm"
        onCancel={() => setResetOpen(false)}
        onConfirm={() => handlePasswordResetRequest()}
        isOpen={resetOpen}
      >
        <Input
          name={"resetEmail"}
          label="Enter your email"
          value={resetEmail}
          type="text"
          onChange={(value) => setResetEmail(value)}
          boldHeader={true}
          autofocus={true}
        />
      </MiniPopup>
    </div>
  );
};

export default Login;
