import React, { useState } from "react";

import MiniPopup from "../../common/contentArea/miniPopup";
import InputMap from "../../common/form/inputMap";
import { IInput, IData } from "../../../types/generics";
import MiniBr from "../../common/contentArea/miniBr";
import InputTable from "../../common/form/inputTable";
import { units, tables } from "../../../utils/allowables";
import Select from "../../common/form/select";
import SecondaryHeader from "../../common/text/secondaryHeader";
import Icons from "../../common/icons/icons";
import Units from "../../common/text/units";

type InfoTabProps = {
  projectInfo: IData;
  levels: IData[];
  onUpdateProjectInfo: (property: string, value: string) => void;
  onUpdateLevels: (property: string, value: string, index: number) => void;
  onAddLevel: (index: number) => void;
  onReorderLevels: (index: number, direction: string) => void;
  onDeleteLevel: (index: number) => void;
  onAddMultipleLevels: (copy: IData) => void;
};

const InfoTab = ({
  projectInfo,
  levels,
  onUpdateProjectInfo,
  onUpdateLevels,
  onAddLevel,
  onReorderLevels,
  onDeleteLevel,
  onAddMultipleLevels,
}: InfoTabProps) => {
  const [deleteLevelOpen, setDeleteLevelOpen] = useState(false);
  const [resetLevelsOpen, setResetLevelsOpen] = useState(false);
  const [copyLevelOpen, setCopyLevelOpen] = useState(false);
  const [copyLevel, setCopyLevel] = useState<IData>({
    number: 1,
    baseName: "Level",
    height: 0,
    width: 0,
    length: 0,
  });
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);

  const projectInfoInputs: IInput[] = [
    { name: "name", label: "*Project Name", type: "text" },
    {
      name: "address",
      label: "Project Address",
      type: "text",
    },
    { name: "jobID", label: "Job ID", type: "text" },
    { name: "date", label: "Project Date", type: "date" },
  ];

  const numberInputWidth = 75;
  const levelInputs: IInput[] = [
    {
      name: "name",
      label: "Level Name",
      type: "text",
      autofocus: true,
    },
    {
      name: "height",
      label: <Units text="Height" units={projectInfo.units} type="length" />,
      type: "number",
      setWidth: numberInputWidth,
    },
    {
      name: "elevation",
      label: <Units text="Elevation" units={projectInfo.units} type="length" />,
      type: "number",
      setWidth: numberInputWidth,
      disabled: true,
    },
    {
      name: "width",
      label: <Units text="Width" units={projectInfo.units} type="length" />,
      type: "number",
      setWidth: numberInputWidth,
    },
    {
      name: "length",
      label: <Units text="Length" units={projectInfo.units} type="length" />,
      type: "number",
      setWidth: numberInputWidth,
    },
  ];

  const levelCopyInputs: IInput[] = [
    {
      name: "number",
      label: "Number of Copies",
      type: "number",
    },
    {
      name: "baseName",
      label: "Base Name",
      type: "text",
    },
    {
      name: "height",
      label: "Height",
      type: "number",
    },
    {
      name: "width",
      label: "Width",
      type: "number",
    },
    {
      name: "length",
      label: "Length",
      type: "number",
    },
  ];

  const handleSelectLevel = (index: number) => {
    setSelectedLevel(index);
    setDeleteLevelOpen(true);
  };

  const handleUpdateLevelCopy = (property: string, value: string) => {
    let updatedCopyLevel = { ...copyLevel };
    updatedCopyLevel[property] = value;
    setCopyLevel(updatedCopyLevel);
  };

  return (
    <div className="row">
      <div className="col-3">
        <SecondaryHeader text="Header Info" align="left" />
        <InputMap
          inputs={projectInfoInputs}
          onChange={onUpdateProjectInfo}
          data={projectInfo}
        />
        <Select
          name="units"
          label="Units"
          options={units}
          selectedOption={projectInfo.units}
          onChange={(value: string) => onUpdateProjectInfo("units", value)}
        />
        <Select
          name="table"
          label="Code Book"
          options={tables}
          selectedOption={projectInfo.table}
          onChange={(value: string) => onUpdateProjectInfo("table", value)}
        />
      </div>
      <div className="col">
        <SecondaryHeader text="Levels" />
        <div className="row">
          {levels.length > 0 ? (
            <InputTable
              data={levels}
              inputs={levelInputs}
              onChange={onUpdateLevels}
              onReorder={onReorderLevels}
              length={levels.length}
              onDelete={handleSelectLevel}
            />
          ) : (
            <p>No levels added yet</p>
          )}
          <MiniBr />
          <div className="row">
            <div className="col text-center">
              <button
                className="custom-button btn-dark"
                onClick={() => onAddLevel(levels.length)}
              >
                <Icons iconType="add" /> Add New Blank Level
              </button>
            </div>
            <div className="col text-center">
              <button
                className="custom-button btn-dark"
                onClick={() => setCopyLevelOpen(true)}
              >
                <Icons iconType="add" /> Add Multiple Levels
              </button>
            </div>
          </div>
          <MiniBr height={25} />
        </div>
        {levels.length > 0 && (
          <div className="text-center">
            <button
              className="custom-button btn-light"
              onClick={() => setResetLevelsOpen(true)}
            >
              <Icons iconType="undo" /> Reset Levels
            </button>
            <MiniBr />
          </div>
        )}
      </div>
      <MiniPopup
        type="confirm"
        onCancel={() => setCopyLevelOpen(false)}
        onConfirm={() => onAddMultipleLevels(copyLevel)}
        isOpen={copyLevelOpen}
      >
        <b>Add Multiple Levels</b>
        <InputMap
          inputs={levelCopyInputs}
          onChange={handleUpdateLevelCopy}
          data={copyLevel}
        />
      </MiniPopup>
      <MiniPopup
        type="confirm"
        onCancel={() => setDeleteLevelOpen(false)}
        onConfirm={() => onDeleteLevel(selectedLevel!)}
        isOpen={deleteLevelOpen}
      >
        Delete this level?
      </MiniPopup>
      <MiniPopup
        type="confirm"
        onCancel={() => setResetLevelsOpen(false)}
        onConfirm={() => onAddLevel(-1!)}
        isOpen={resetLevelsOpen}
      >
        Delete all levels?
      </MiniPopup>
    </div>
  );
};

export default InfoTab;
