import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContext from "../../context/loadingContext";

import {
  getProjects,
  activateProject,
  permanentlyDeleteProject,
} from "../../services/projectService";
import Project from "./project";
import { IProject } from "../../types/projectTypes";
import TabbedArea from "../common/contentArea/tabbedArea";
import SearchBox from "../common/data/searchBox";
import DateSearch from "../common/data/dateSearch";
import { IData } from "../../types/generics";

const ProjectsHome = () => {
  let navigate = useNavigate();
  const { setLoading, setProgress } = useContext(LoadingContext);
  const [projects, setProjects] = useState<IProject[]>([]);
  const tabs = ["active", "marked for deletion"];
  const [selectedTab, setSelectedTab] = useState("active");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateSearch, setDateSearch] = useState<IData>({ from: "", to: "" });

  const loadProjects = useCallback(async () => {
    setLoading(true);
    setProgress(1);
    const res = await getProjects(async (p) => setProgress(p));
    if (res.status === 200) {
      setProjects(res.data);
    } else toast.error(res.data);
    setLoading(false);
  }, [setLoading, setProjects, setProgress]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  const handleSelectProject = async (project: IProject) => {
    if (project.deleted) {
      setLoading(true);
      setProgress(1);
      const res = await activateProject(project._id!, async (p) =>
        setProgress(p)
      );
      if (res.status === 200) {
        toast.success("Project Activated");
        return loadProjects();
      } else toast.error(res.data);
      setLoading(false);
    } else navigate(`/projects?id=${project._id}`);
  };

  const handlePermaDeleteProject = async (project: IProject) => {
    setLoading(true);
    setProgress(1);
    const res = await permanentlyDeleteProject(project._id!, async (p) =>
      setProgress(p)
    );
    if (res.status === 200) {
      toast.success("Project Permanently Deleted");
      return loadProjects();
    } else toast.error(res.data);
    setLoading(false);
  };

  const filterData = () => {
    let filteredProjects = projects.filter((p: any) => {
      return selectedTab === "active" ? !p.deleted : p.deleted;
    });
    if (searchQuery)
      filteredProjects = filteredProjects.filter(
        (p: IProject) =>
          p.info.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          p.info.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
          p.info.jobID.toLowerCase().includes(searchQuery.toLowerCase())
      );
    if (dateSearch.to !== "" || dateSearch.from !== "") {
      const to = dateSearch.to ? new Date(dateSearch.to) : null;
      const from = dateSearch.from ? new Date(dateSearch.from) : null;
      filteredProjects = filteredProjects.filter((p: IProject) => {
        const projectDate = p.info.date ? new Date(p.info.date) : null;
        let display = true;
        if (projectDate) {
          if (to && projectDate > to) display = false;
          if (from && projectDate < from) display = false;
        } else display = false;
        return display;
      });
    }
    return filteredProjects;
  };

  return (
    <>
      <div className="row">
        <div className="col text-center">
          <SearchBox
            query={searchQuery}
            onSearch={(query: string) => setSearchQuery(query)}
            placeholder="Search by name, address, or job ID..."
          />
        </div>
        <div className="col text-center">
          <DateSearch dates={dateSearch} setDates={setDateSearch} />
        </div>
        <div className="col-2">
          <button
            className="custom-button btn-dark"
            onClick={() => navigate(`/projects?id=new`)}
          >
            New Project
          </button>
        </div>
      </div>
      <TabbedArea
        tabPlacement="top"
        tabs={tabs}
        icons={["open", "markDelete"]}
        onSelectTab={setSelectedTab}
        selectedTab={selectedTab}
      >
        {selectedTab === "marked for deletion" && (
          <p className="text-center">
            <b>
              Projects marked for deletion do not count against your maximum
              allowed projects
            </b>
            <br />
            They can be unmarked and reactivated while they still appear here as
            long as you are under your subscription cap.
            <br />
            Projects will be automatically permanently deleted after one month.
          </p>
        )}
        {filterData().map((project, i) => (
          <Project
            key={i}
            project={project}
            onSelectProject={handleSelectProject}
            onPermaDeleteProject={handlePermaDeleteProject}
          />
        ))}
      </TabbedArea>
    </>
  );
};

export default ProjectsHome;
