import React from "react";

import VerticalTabs from "../selecting/verticalTabs";
import HorizontalTabs from "./../selecting/horizontalTabs";
import { Side } from "../../../types/generics";

type TabbedAreaProps = {
  tabPlacement: Side;
  tabs: string[];
  icons?: string[];
  selectedTab: string;
  onSelectTab: (tab: string) => void;
  contentClassName?: string;
  children: any;
};

const TabbedArea = ({
  tabPlacement,
  tabs,
  icons,
  selectedTab,
  onSelectTab,
  contentClassName,
  children,
}: TabbedAreaProps) => {
  const TabType = tabPlacement === "top" ? HorizontalTabs : VerticalTabs;

  return (
    <div className="small-padding">
      <TabType
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectTab={onSelectTab}
        side={tabPlacement}
        icons={icons}
      />
      <div
        className={
          "tab-area " +
          (tabPlacement === "top" ? "horizontal " : "vertical ") +
          tabPlacement +
          (contentClassName ? ` ${contentClassName}` : "")
        }
      >
        {children}
      </div>
    </div>
  );
};

export default TabbedArea;
