import React from "react";
import { IData } from "../../../types/generics";
import Table from "../../common/table/table";
import Subscript from "../../common/text/subscript";
import TertiaryHeader from "../../common/text/tertiaryHeader";

type SeismicCalculatedFieldsProps = {
  seismicInfo: IData;
  maxElevation: number;
};

const SeismicCalculatedFields = ({
  seismicInfo,
  maxElevation,
}: SeismicCalculatedFieldsProps) => {
  const seismicCalcs = [
    {
      id: "siteCoefficientShort",
      label: (
        <Subscript
          preText="Site Coefficient for Short Period (F"
          subText="a"
          postText=")"
        />
      ),
      alt: "See 11.4.8",
    },
    {
      id: "siteCoefficientOne",
      label: (
        <Subscript
          preText="Site Coefficient for 1-Sec. Period (F"
          subText="V"
          postText=")"
        />
      ),
      alt: "See 11.4.8",
    },
    {
      id: "mceSraShort",
      label: (
        <Subscript
          preText="MCE Spectral Response Acceleration, Short T (S"
          subText="MS"
          postText=")"
        />
      ),
    },
    {
      id: "mceSraOne",
      label: (
        <Subscript
          preText="MCE Spectral Response Acceleration, 1-Sec. T (S"
          subText="M1"
          postText=")"
        />
      ),
    },
    {
      id: "sraShortSds",
      label: (
        <Subscript
          preText="Spectral Response Acceleration, Short T (S"
          subText="DS"
          postText=")"
        />
      ),
    },
    {
      id: "sraOneSd1",
      label: (
        <Subscript
          preText="Spectral Response Acceleration, 1-Sec. T (S"
          subText="D1"
          postText=")"
        />
      ),
    },
    {
      id: "riskImportanceFactor",
      label: "Seismic Risk Importance Factor",
    },
    {
      id: "effectiveHeight",
      label: "Effective Height (h)",
      location: maxElevation.toFixed(1),
    },
    {
      id: "designCategory",
      label: "Seismic Design Category",
      location: seismicInfo.designCategory.toUpperCase(),
    },
  ];

  const columns = [
    {
      path: "label",
      label: "",
      content: (f: any) => f.label,
    },
    {
      path: "x",
      label: "X",
    },
    {
      path: "y",
      label: "Y",
    },
  ];

  const xyData = [
    {
      label: "Response Modification Factor (R)",
      content: seismicInfo.responseModificationFactor,
    },
    {
      label: <>System Overstrength Factor (&Omega;)</>,
      content: seismicInfo.systemOverstrengthFactor,
    },
    {
      label: "Deflection Amplification Factor (Cd)",
      content: seismicInfo.deflectionAmplificationFactor,
    },
    {
      label: "Period Parameter (Ct)",
      content: seismicInfo.periodParameterCt,
    },
    {
      label: "Period Upper Limit Coefficient (Cu)",
      content: seismicInfo.periodUpperLimitCoefficient,
    },
    {
      label: "Approximate Building Period (Ta)",
      content: seismicInfo.approxBuildingPeriod,
    },
    {
      label: (
        <Subscript
          preText="Deign Period Upper Limit (T"
          subText="MAX"
          postText=")"
        />
      ),
      content: seismicInfo.designPeriodUpperLimit,
    },
    {
      label: "Calculated Seismic Response Coefficient",
      content: seismicInfo.calculatedSeismicResponseCoefficient,
    },
    {
      label: "Cs Need Not Exceed",
      content: seismicInfo.csNeedNotExceed,
    },
    {
      label: "Cs Shall Not Be Less Than",
      content: seismicInfo.csShallNotBeLessThan,
    },
    {
      label: "Design Seismic Response Coefficient (Cs)",
      content: seismicInfo.designSeismicResponseCoefficient,
    },
  ];

  const tableData = xyData.map((d) => {
    return {
      label: d.label,
      x: d.content["x"].toFixed(3),
      y: d.content["y"].toFixed(3),
    };
  });

  const renderField = (c: any, xy?: string) => {
    let text = seismicInfo[c.id];
    text = xy ? text[xy] : text;
    return (
      <div className="row mini-list-item" key={c.id}>
        <div className="col">
          <b>{c.label}:</b>
        </div>
        <div className="col-3 text-left">
          {c.location
            ? c.location
            : isNaN(seismicInfo[c.id])
            ? String(seismicInfo[c.id])
            : seismicInfo[c.id] === 0 && c.alt
            ? c.alt
            : seismicInfo[c.id].toFixed(3)}
        </div>
      </div>
    );
  };

  return (
    <>
      <TertiaryHeader text="Calculated Fields" />
      <div className="row">
        <div className="col">{seismicCalcs.map((c) => renderField(c))}</div>
        <div className="col">
          {<Table columns={columns} data={tableData} />}
        </div>
      </div>
    </>
  );
};

export default SeismicCalculatedFields;
