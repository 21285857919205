import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

import Tooltip from "./../common/tooltip/tooltip";

const CreditCardEntry = () => {
  return (
    <>
      <Tooltip
        direction="top"
        content={
          <>
            <b>Card Details</b>
            <br />
            Your card details will be securely stored by Stripe for recurring
            billing.
          </>
        }
      >
        <b>Enter Card Details</b>
      </Tooltip>
      <CardElement
        options={{
          style: {
            base: {
              color: "#32325d",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          },
        }}
      />
    </>
  );
};

export default CreditCardEntry;
