import React from "react";
import { ITableColumns } from "../../../types/generics";

type TableHeaderProps = {
  columns: ITableColumns[];
};

const TableHeader = ({ columns }: TableHeaderProps) => {
  return (
    <thead>
      <tr className="table-header">
        {columns.map((column) => (
          <th key={column.path} className="table-header-cell">
            {column.label}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
