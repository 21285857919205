import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

type TooltipProps = {
  content: any;
  children: any;
  clickable?: boolean;
  customClass?: string;
  direction?: string;
};

const Tooltip = ({ children, direction, content }: TooltipProps) => {
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive(!active);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setActive(false)}>
      <div
        className="tooltip-wrapper"
        onMouseEnter={toggle}
        onMouseLeave={toggle}
        onClick={() => setActive(true)}
      >
        {children}
        {active && (
          <span className={`tooltip-tip ${direction || "right"}`}>
            {content}
          </span>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Tooltip;
