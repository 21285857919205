import React, { useEffect } from "react";
import { IData } from "../../../types/generics";
import DeadLoad from "./deadLoad";
import { IDeadLoad, IWeightObject } from "../../../types/projectTypes";
import SecondaryHeader from "../../common/text/secondaryHeader";
import Icons from "../../common/icons/icons";
import Units from "../../common/text/units";

type DeadLoadProps = {
  deadLoads: IData[];
  onAddDeadLoad: () => void;
  onUpdateDeadLoadInfo: (
    property: string,
    value: string,
    index: number
  ) => void;
  onUpdateDeadLoadArray: (
    property: string,
    value: IWeightObject[],
    index: number,
    skipCalc: boolean
  ) => void;
  onDeleteDeadLoad: (index: number) => void;
  units: string;
  deadLoadTotalWeight: number;
  levels: IData[];
};

const DeadLoads = ({
  deadLoads,
  onAddDeadLoad,
  onUpdateDeadLoadInfo,
  onUpdateDeadLoadArray,
  onDeleteDeadLoad,
  units,
  deadLoadTotalWeight,
  levels,
}: DeadLoadProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SecondaryHeader text="Dead Loads" />
      <div className="list-item">
        {deadLoads.length > 0 && (
          <SecondaryHeader
            text={
              <Units
                text={deadLoadTotalWeight}
                units={units}
                type="pressure"
                noParentheses={true}
              />
            }
          />
        )}
      </div>
      {deadLoads.map((d, i) => (
        <DeadLoad
          key={i}
          deadLoad={d as IDeadLoad}
          onUpdateDeadLoadInfo={(property: string, value: string) =>
            onUpdateDeadLoadInfo(property, value, i)
          }
          onUpdateDeadLoadArray={(
            property: string,
            value: IWeightObject[],
            skipCalc: boolean
          ) => onUpdateDeadLoadArray(property, value, i, skipCalc)}
          onCopyDeadLoad={onAddDeadLoad}
          onDeleteDeadLoad={() => onDeleteDeadLoad(i)}
          units={units}
          levels={levels}
        />
      ))}
      <button
        className="custom-button btn-dark"
        onClick={() => onAddDeadLoad()}
      >
        <Icons iconType="add" /> Add New Empty Dead Load
      </button>
    </div>
  );
};

export default DeadLoads;
